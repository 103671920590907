import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';
import { GET_PALLET_LISTS } from '../graph-ql/queries/palletList';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
	optimisticResponse?: any;
}

const mutation = gql`
	mutation deletePalletListItems($input: DeleteShipmentListsInput!) {
		deleteShipmentPalletLists(input: $input) {
			deletedIds
		}
	}
`;

const getOptimisticResponse = (palletListIds: string[]) => {
	const response: any = {
		deleteShipmentPalletLists: {
			__typename: 'Mutation',
			deletedIds: palletListIds,
		},
	};

	return response;
};
const GET_SHIPMENT = gql`
	query palletList($id: String!) {
		shipment(id: $id) {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
			lists {
				id
				list {
					id
					createdAt
					itemCount
					shipmentCount
					shipmentCount
					lastUpdatedAt
					palletType
					emailDeliveryCount
					createdBy {
						name
						id
					}
					items {
						id
						createdAt
						poNumber
						product {
							id
							description
							eanCode
						}
					}
				}
			}
		}
	}
`;

function getUpdateFunction(shipmentId: string): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				deleteShipmentPalletLists: { deletedIds },
			},
		}
	) => {
		const { shipment }: any = cache.readQuery({
			query: GET_SHIPMENT,
			variables: { id: shipmentId },
		});

		const lists = shipment.lists.filter((list: any) => !deletedIds.includes(list.id));
		cache.writeQuery({
			query: GET_SHIPMENT,
			data: {
				shipment: {
					...shipment,
					palletListCount: shipment.palletListCount - deletedIds.length,
					lists,
				},
			},
		});
	};

	return updater;
}

function getMutation(variables: { shipmentId: string }, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(variables.shipmentId),
			onCompleted,
			onError,
			refetchQueries: [{ query: GET_PALLET_LISTS }],
		},
		optimisticResponse: getOptimisticResponse,
	};
	return mutationObject;
}

export default getMutation;

import React, { useState, useEffect, FormEvent } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SectionTitle } from '../../../components/shared/SectionTitle/SectionTitle.component';
import { gql, ApolloError } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Styled from '@emotion/styled/macro';
import { useMutationObject } from '../../../graph-ql/useMutationObject';
import updateDeliverySettingsMutation from '../../../mutations/UpdateDeliverySettingsMutation';
import { useToasts } from 'react-toast-notifications';
import { ValidationUtils } from '../../../util/ValidationUtils';
import { Card } from '../../../components/Card/Card.component';

export const DeliverySettingsContainer = () => {
	const { addToast } = useToasts();
	const { loading, error, data } = useQuery(GET_DELIVERY_SETTINGS);
	if (error) {
		throw error;
	}
	const maxCount = 240;
	const maxCountTextMessage = 2000;
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [fromAddress, setFromAddress] = useState('');
	const [fromAddressError, setFromAddressError] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [emailAddressError, setEmailAddressError] = useState(false);
	const [copyAddress, setCopyAddress] = useState('');
	const [copyAddressError, setCopyAddressError] = useState(false);
	const [description, setDescription] = useState('');

	useEffect(() => {
		if (data) {
			setSubject(data.deliverySettings[0].subject);
			setMessage(data.deliverySettings[0].message);
			setFromAddress(data.deliverySettings[0].fromAddress);
			setEmailAddress(data.deliverySettings[0].emailAddress);
			setCopyAddress(data.deliverySettings[0].copyAddress);
			setDescription(data.deliverySettings[0].description);
		}
	}, [data]);

	const reset = () => {
		setSubject(data.deliverySettings[0].subject);
		setMessage(data.deliverySettings[0].message);
		setFromAddress(data.deliverySettings[0].fromAddress);
		setFromAddressError(false);
		setEmailAddress(data.deliverySettings[0].emailAddress);
		setEmailAddressError(false);
		setCopyAddress(data.deliverySettings[0].copyAddress);
		setCopyAddressError(false);
		setDescription(data.deliverySettings[0].description);
	};

	const saveChanges = () => {
		const input = {
			id: data.deliverySettings[0].id,
			description,
			subject,
			message,
			fromAddress,
			emailAddress,
			copyAddress,
		};

		updateSettings(input, updateDeliverySettingsMutation().optimisticResponse(input));
	};

	const hasChanged = () => {
		let changed = false;
		changed = changed || data.deliverySettings[0].subject !== subject;
		changed = changed || data.deliverySettings[0].message !== message;
		changed = changed || data.deliverySettings[0].fromAddress !== fromAddress;
		changed = changed || data.deliverySettings[0].emailAddress !== emailAddress;
		changed = changed || data.deliverySettings[0].copyAddress !== copyAddress;
		changed = changed || data.deliverySettings[0].description !== description;
		return changed;
	};

	const onChangeInputField = (fieldName: string, event: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const value = event.currentTarget.value;
		const maxLength = fieldName === 'message' ? maxCountTextMessage : maxCount;
		if (event.currentTarget.value.length > maxLength) {
			return;
		}
		switch (fieldName) {
			case 'description':
				setDescription(value);
				break;
			case 'fromAddress':
				setFromAddress(value);

				if (ValidationUtils.validateEmails(value, 1)) setFromAddressError(false);
				break;
			case 'emailAddress':
				setEmailAddress(value);
				if (ValidationUtils.validateEmails(value)) setEmailAddressError(false);
				break;
			case 'copyAddress':
				setCopyAddress(value);
				if (ValidationUtils.validateEmails(value, 5, 0)) setCopyAddressError(false);
				break;
			case 'subject':
				setSubject(value);
				break;
			case 'message':
				setMessage(value);
				break;
		}
	};

	const hasErrors = () => {
		const errors = fromAddressError || emailAddressError || copyAddressError;
		return errors;
	};

	const onUpdateSettingsSuccess = (data: any) => {
		addToast('updated settings', { appearance: 'success', autoDismiss: true });
	};
	const onUpdateSettingsError = (error: ApolloError) => {
		addToast('error updating settings', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [updateSettings] = useMutationObject(updateDeliverySettingsMutation(onUpdateSettingsSuccess, onUpdateSettingsError));
	return (
		<Container fluid>
			<Row>
				<Col>
					<SectionTitle title="Delivery Settings" />
				</Col>
			</Row>
			{!loading && data && (
				<>
					<Row>
						<Col sm={6}>
							<Card bordered>
								<InputGroup>
									<label>
										Setting Description ({description.length}/{maxCount})
									</label>
									<Input type="text" value={description} onChange={(e) => onChangeInputField('description', e)} />
								</InputGroup>
								<InputGroup error={fromAddressError}>
									<label>
										Sender Email ({fromAddress.length}/{maxCount})
									</label>
									<Input
										error={fromAddressError}
										type="text"
										onBlur={() => setFromAddressError(!ValidationUtils.validateEmails(fromAddress, 1))}
										value={fromAddress}
										onChange={(e) => onChangeInputField('fromAddress', e)}
									/>
								</InputGroup>
								<InputGroup error={emailAddressError}>
									<label>
										Receiver email ({emailAddress.length}/{maxCount})
									</label>
									<Input
										error={emailAddressError}
										type="text"
										onBlur={() => setEmailAddressError(!ValidationUtils.validateEmails(emailAddress))}
										value={emailAddress}
										onChange={(e) => onChangeInputField('emailAddress', e)}
									/>
								</InputGroup>
								<InputGroup error={copyAddressError}>
									<label>
										BCC Address ({copyAddress.length}/{maxCount})
									</label>
									<Input
										error={copyAddressError}
										type="text"
										onBlur={() => setCopyAddressError(!ValidationUtils.validateEmails(copyAddress, 5, 0))}
										value={copyAddress}
										onChange={(e) => onChangeInputField('copyAddress', e)}
									/>
								</InputGroup>
								<InputGroup>
									<label>
										Email Subject ({subject.length}/{maxCount})
									</label>
									<Input type="text" value={subject} onChange={(e) => onChangeInputField('subject', e)} />
								</InputGroup>
								<InputGroup>
									<label>
										Email Message ({message.length}/{maxCountTextMessage})
									</label>
									<TextArea rows={5} value={message} onChange={(e) => onChangeInputField('message', e)} />
								</InputGroup>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button disabled={!hasChanged() || hasErrors()} size="sm" onClick={saveChanges}>
								Save Changes
							</Button>
							<Button variant="secondary" disabled={!hasChanged()} size="sm" onClick={reset}>
								Restore
							</Button>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};

const GET_DELIVERY_SETTINGS = gql`
	query deliverySetttings {
		deliverySettings {
			id
			description
			fromAddress
			emailAddress
			copyAddress
			subject
			message
		}
	}
`;

const InputGroup = Styled.div<{ width?: number; first?: boolean; last?: boolean; error?: boolean }>`
    width: ${(props) => props.width + 'px' || 'auto'};
    flex-grow: ${(props) => !props.width && '1'};
    padding-left: ${(props) => (props.first ? '0' : '10px')};
    padding-right: ${(props) => (props.last ? '0' : '10px')};
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    label {
        font-size: 13px;
        margin-bottom: 4px;
        color: ${(props) => (props.error ? 'red' : '#777')};
    }
`;
const Input = Styled.input<any>`
    padding: 0 10px;
    border-radius: 4px;
    height: 30px;
    border: ${(props) => (props.error ? '1px solid red' : '1px solid #d6d6d6')};
    color: #777;
    font-size: 14px;
    font-weight: 600;
`;

const TextArea = Styled.textarea`
  padding: 0 10px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    color: #777;
    font-size: 14px;
    font-weight: 600;
`;

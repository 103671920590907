import React from 'react';
import Styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';
import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends React.Component {
	state: Readonly<{ hasError: boolean; isFailedToFetch: boolean; eventId: any }>;
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, isFailedToFetch: true, eventId: null };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Container>
					<div>
						<SadSmiley>:(</SadSmiley>
						<h1>{this.state.isFailedToFetch ? 'This looks like internet connection issues' : 'Something bad happened'}</h1>
						<p>
							{this.state.isFailedToFetch
								? 'It is properly an issue on our side, but please check your internet connection, or try to reload the page.'
								: null}
						</p>
						<p>We have logged the error, and will investigate the reason.</p>
						<p>If this continues to happen pls contact us.</p>
						<div style={{ margin: '10px 0' }}>
							<Button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
						</div>
						<div>
							<Button onClick={() => window.location.reload()}>Reload Page</Button>
						</div>
					</div>
				</Container>
			);
		}

		return this.props.children;
	}
}

const SadSmiley = Styled.h1`
	font-size: 240px;
`;
const Container = Styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		font-size: 16px;
		font-weight: 500;
	}
	
`;

import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { NavigationItem } from './navigation-item.component';

export class TopNavigation extends React.Component {
	static NavItem = NavigationItem;

	render() {
		const { children } = this.props;
		return (
			<nav
				css={css`
					height: 100%;
				`}
			>
				<ul
					css={css`
						display: flex;
						align-items: center;
						list-style: none;
						padding: 0;
						margin: 0;
						height: 100%;
					`}
				>
					{children}
				</ul>
			</nav>
		);
	}
}

import { IPalletList } from "../models/graphql";

export class PalletListUtils {
	static sortByLastUpdated(a: IPalletList, b: IPalletList, direction: 1 | -1 = 1): number {
		if(a.lastUpdatedAt > b.lastUpdatedAt) return -1 * direction;
		if(a.lastUpdatedAt < b.lastUpdatedAt) return 1 * direction;
		return 0;
	}


	private static getStatus(palletList: IPalletList): number {
		if(palletList.emailDeliveryCount) return 3;
		if(palletList.shipmentCount) return 2;
		if(palletList.itemCount) return 1;
		return 0;
	}
	
	/**
	 * Sort status so shipments with no pallet lists is first, with pallet lsit second
	 * and with email deliveries, last
	 * @param a 
	 * @param b 
	 */
	static sortByStatus(a: IPalletList, b: IPalletList, direction : 1 | -1 = 1): number {
		const aStatus = PalletListUtils.getStatus(a);
		const bStatus = PalletListUtils.getStatus(b);
		
		if(aStatus > bStatus) return 1 * direction;
		if(aStatus < bStatus) return -1 * direction;
		return 0;
	}

	static filterSent(palletList: IPalletList): boolean {
		return palletList.emailDeliveryCount === 0;
	}
}
import React, { FunctionComponent } from 'react';
import Styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import Icon from '@mdi/react';

export interface StatisticCardProps {
	color: string;
	mdiIcon: string;
	title: string;
	text: string;
}
export const StatisticCard: FunctionComponent<any> = ({ color, mdiIcon, title, children }) => {
	return (
		<Card>
			<CardIcon color={color}>
				<Icon path={mdiIcon} size={'35px'} color="#fff" />
			</CardIcon>
			<TitleAndText>
				<Title color={color}>{title}</Title>
				<Text>{children}</Text>
			</TitleAndText>
		</Card>
	);
};

const TitleAndText = Styled.div`
    width: calc(100% - 80px);
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.75rem;    
`;

const Title = Styled.h5`
    font-weight: 500;
    font-size: 16px;
    color: ${(props) => props.color};
`;

const Text = Styled.div`
    margin: 0;
    font-size: 14px;

`;

const borderLeftRadius = css`
	border-bottom-left-radius: 0.25rem;
	border-top-left-radius: 0.25rem;
`;

const borderRightRadius = css`
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
`;

export const Card = Styled.div`
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff; 
    margin-bottom: 1rem;
    ${borderLeftRadius};
    ${borderRightRadius};
`;

export const CardIcon = Styled.div`
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80px;
    background-color: ${(props) => props.color};
    ${borderLeftRadius};
`;

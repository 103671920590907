// src/components/PrivateRoute.js

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../../auth';
import { useLocation } from 'react-router-dom';
type PrivateRouteProps = {
	path: any;
	children: any;
};

const PrivateRoute = ({ children, path, ...rest }: PrivateRouteProps) => {
	const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
	const location = useLocation();

	useEffect(() => {
		if (loading || isAuthenticated) {
			return;
		}
		const fn = async () => {
			await loginWithRedirect({
				appState: { targetUrl: location.pathname },
			});
		};
		fn();
	}, [loading, isAuthenticated, loginWithRedirect, path, location.pathname]);

	//   const render = (props: any) =>
	//     isAuthenticated === true ? <Component {...props} /> : null;

	return (
		<Route path={path} {...rest}>
			{isAuthenticated ? children : <h2>Logging in!</h2>}
		</Route>
	);
};

export default PrivateRoute;

import React, { FunctionComponent } from 'react';
import Styled from '@emotion/styled/macro';

export const Card: FunctionComponent<{ bordered?: boolean; colored?: boolean; title?: string; children: any }> = ({
	colored,
	bordered,
	title,
	children,
}) => {
	if (colored) {
		return (
			<ColoredContainer>
				{title && <TitleContainer>{title}</TitleContainer>}
				<ContentContainer>{children}</ContentContainer>
			</ColoredContainer>
		);
	} else if (bordered) {
		return (
			<BorderedContainer>
				{title && <TitleContainer>{title}</TitleContainer>}
				<ContentContainer>{children}</ContentContainer>
			</BorderedContainer>
		);
	} else {
		return <Container>{children}</Container>;
	}
};

const Container = Styled.div`
	border-radius: 4px;
	margin-bottom: 1rem;

`;

const BorderedContainer = Styled(Container)`
	border: 1px solid #ddd;

`;

const TitleContainer = Styled.div`
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #ddd;
	font-family: 'roboto';
	font-weight: bold;
	font-size: 13px;
	letter-spacing: 1px;
	
`;

const ContentContainer = Styled.div`
	padding: 0.5rem 1rem;
`;
const ColoredContainer = Styled(Container)`
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 2px 4px rgba(0,0,0, 0.05);
`;

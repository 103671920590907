export class ValidationUtils {
	public static validateEmail(email: string): boolean {
		const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegexp.test(email);
	}

	public static validateEmails(emails: string, maxNoOfEmails: number = 5, minNoOfEmails: number = 1): boolean {
		const emailArray = emails.split(',');
		if (emailArray.length === 1 && emailArray[0].trim().length === 0 && minNoOfEmails === 0) {
			return true;
		}
		if (emailArray.length > maxNoOfEmails) {
			return false;
		}
		let valid = true;
		let count = 0;
		emailArray.forEach((email) => {
			count++;
			valid = valid && ValidationUtils.validateEmail(email.trim());
		});

		if (count < minNoOfEmails) {
			return false;
		}
		return valid;
	}
}

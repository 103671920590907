import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PalletListsContainer from '../pallet-lists/Pallet-Lists.container';
import PalletListOverviewContainer from '../pallet-list-overview/Pallet-List-Overview.container';
import { PalletListContainer } from '../Pallet-List/PalletList.container';
import { ShipmentsContainer } from '../Shipments/Shipments.container';
import { ShipmentContainer } from '../Shipment/Shipment.container';
import { DeliverySettingsContainer } from '../SettingsContainers/DeliverySettingsContainer';
export const ShipmentServiceContainer = () => {
	const { path } = useRouteMatch();
	return (
		<div style={{ padding: '0 1rem', width: '100%', minHeight: '100%', backgroundColor: '#f1f1f1' }}>
			<Switch>
				<Route exact path={`${path}`}>
					<PalletListOverviewContainer />
				</Route>
				<Route exact path={`${path}/pallet-lists`}>
					<PalletListsContainer />
				</Route>
				<Route exact path={`${path}/pallet-lists/:id`}>
					<PalletListContainer />
				</Route>
				<Route exact path={`${path}/shipments`}>
					<ShipmentsContainer />
				</Route>
				<Route exact path={`${path}/shipments/:id`}>
					<ShipmentContainer />
				</Route>
				<Route exact path={`${path}/settings/delivery-settings`}>
					<DeliverySettingsContainer />
				</Route>
			</Switch>
		</div>
	);
};

import { useMutation } from '@apollo/react-hooks';
import { IMutationObject } from '../models/mutation/IMutationObject';

export function useMutationObject<TData = any, TVariables = Record<string, any>>({ mutation, options }: IMutationObject) {
	const [doMutate, { data: mutationData, error }] = useMutation(mutation, options);

	const mutate = (input: any, optimisticResponse: any) => {
		doMutate({
			variables: {
				input: {
					...input,
				},
			},
			optimisticResponse,
		});
	};
	return [mutate, mutationData, error];
}

import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
	optimisticResponse: any;
}

const getOptimisticResponse = (id: string, palletListIds: string[]) => {
	const response: any = {
		addPalletListsToShipment: {
			__typename: 'Mutation',
			shipmentLists: [],
		},
	};
	palletListIds.forEach((id, i) => {
		const shipmentList = {
			__typename: 'ShipmentList',
			id: -i,
			list: {
				__typename: 'PalletList',
				id,
				createdAt: new Date(),
				itemCount: 0,
				shipmentCount: 0,
				emailDeliveryCount: 0,

				createdBy: {
					__typename: 'DisplayUser',
					id: 0,
					name: '',
				},

				lastUpdatedAt: new Date(),
				items: [],
			},
		};
		response.addPalletListsToShipment.shipmentLists.push(shipmentList);
	});
	return response;
};
const mutation = gql`
	mutation addPalletListsToShipment($input: CreateShipmentPalletListsInput!) {
		addPalletListsToShipment(input: $input) {
			shipmentLists {
				id
				list {
					id
					createdAt
					itemCount
					shipmentCount
					shipmentCount
					lastUpdatedAt
					emailDeliveryCount
					createdBy {
						name
						id
					}
					items {
						id
						createdAt
						poNumber
						product {
							id
							description
							eanCode
						}
					}
				}
			}
		}
	}
`;

const GET_SHIPMENT = gql`
	query shipment($id: String!) {
		shipment(id: $id) {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
			lists {
				id
				list {
					id
					createdAt
					itemCount
					palletType
					shipmentCount
					shipmentCount
					lastUpdatedAt
					emailDeliveryCount
					createdBy {
						name
						id
					}
					items {
						id
						createdAt
						poNumber
						product {
							id
							description
							eanCode
						}
					}
				}
			}
		}
	}
`;

function getUpdateFunction(shipmentId: string): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				addPalletListsToShipment: { shipmentLists },
			},
		}
	) => {
		const { shipment }: any = cache.readQuery({
			query: GET_SHIPMENT,
			variables: { id: shipmentId },
		});
		shipment.lists = shipmentLists.concat([...shipment.lists]);
		shipment.palletListCount = shipment.lists.length;
		cache.writeQuery({
			query: GET_SHIPMENT,
			data: {
				shipment: {
					...shipment,
				},
			},
		});
	};

	return updater;
}

function getMutation(variables: { shipmentId: string }, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(variables.shipmentId),
			onCompleted,
			onError,
		},
		optimisticResponse: getOptimisticResponse,
	};
	return mutationObject;
}

export default getMutation;

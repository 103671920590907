import React from 'react';
import { gql, ApolloError } from 'apollo-boost';
import { Container, Row, Col } from 'react-bootstrap';
import PalletListsTable from '../../components/palletlist-list-table/Pallet-Lists-Table.component';
import { useQuery } from '@apollo/react-hooks';
import { SectionTitle } from '../../components/shared/SectionTitle/SectionTitle.component';
import { TitleAndButton } from '../pallet-list-overview/Pallet-List-Overview.container';
import { IconButton } from '../../components/shared/IconButton/Icon-Button.component';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import createPalletListMutation from '../../mutations/CreatePalletListMutation';
import { useHistory } from 'react-router-dom';
import { NewPalletListButton } from '../../components/Buttons/NewPalletListButton';
import { ListType } from '../../util/GlobalConstants';

export const PalletListsContainer = () => {
	const { loading, error, data } = useQuery(GET_PALLET_LISTS);
	if (error) {
		throw error;
	}
	let palletLists = [];
	const history = useHistory();

	const onCreatePalletListSuccess = (data: any) => {
		history.push('pallet-lists/' + data.createPalletList.palletList.id);
	};

	const onCreatePalletListError = (error: ApolloError) => {};
	const [createPalletList] = useMutationObject(createPalletListMutation(onCreatePalletListSuccess, onCreatePalletListError));

	const addPalletList = (listType: ListType) => {
		createPalletList({ description: listType, palletListType: listType });
	};

	if (!loading && !error) {
		palletLists = data.palletLists;
	}
	return (
		<Container fluid>
			<Row>
				<Col>
					<SectionTitle title="Pallet Lists" />
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<TitleAndButton>
								<h3>Active Pallet List</h3>
								<div>
									<NewPalletListButton addPalletList={addPalletList} />
								</div>
							</TitleAndButton>
						</Col>
					</Row>
					<Row>
						<Col>
							<PalletListsTable selectedPalletLists={[]} palletLists={palletLists} loading={loading} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default PalletListsContainer;

const GET_PALLET_LISTS = gql`
	{
		palletLists {
			id
			createdAt
			itemCount
			description
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
		}
	}
`;

import React, { FunctionComponent } from 'react';
import { ApolloError } from 'apollo-boost';
import { Container, Row, Col } from 'react-bootstrap';
import { ShipmentsTable } from '../../components/ShipmentsTable/ShipmentsTable.component';
import { useQuery } from '@apollo/react-hooks';
import { SectionTitle } from '../../components/shared/SectionTitle/SectionTitle.component';
import { TitleAndButton } from '../pallet-list-overview/Pallet-List-Overview.container';
import { IconButton } from '../../components/shared/IconButton/Icon-Button.component';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import createShipmentMutation from '../../mutations/CreateShipmentMutation';
import { useHistory } from 'react-router-dom';
import { GET_SHIPMENTS } from '../../graph-ql/queries/shipment';

export const ShipmentsContainer: FunctionComponent<any> = () => {
	const { loading, error, data } = useQuery(GET_SHIPMENTS);
	if (error) {
		throw error;
	}
	const history = useHistory();

	const onCreateShipmentSuccess = (data: any) => {
		history.push('shipments/' + data.createShipment.shipment.id);
	};

	const onCreateShipmentError = (error: ApolloError) => {};
	const [createShipment] = useMutationObject(createShipmentMutation(onCreateShipmentSuccess, onCreateShipmentError));
	const addShipment = () => {
		createShipment({ palletListIds: [] });
	};

	let shipments = [];
	if (!loading && !error) {
		shipments = data.shipments;
	}
	return (
		<Container fluid>
			<Row>
				<Col>
					<SectionTitle title="Shipments" />
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<TitleAndButton>
								<h3>Shipment List</h3>
								<div>
									<IconButton onClick={() => addShipment()} variant="primary" size="sm">
										New Shipment
									</IconButton>
								</div>
							</TitleAndButton>
						</Col>
					</Row>
					<Row>
						<Col>
							<ShipmentsTable shipments={shipments} loading={loading} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default ShipmentsContainer;

import { IMutationObject } from './../models/mutation/IMutationObject';
import { gql, ApolloError } from 'apollo-boost';

const mutation = gql`
    mutation updateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input) {
            product {
                id
            }
        }
    }
`;

function getMutation(variables?: any, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
    const mutationObject: IMutationObject = {
        mutation,
        options: {
            onCompleted,
            onError,
        },
    }
    return mutationObject;
}

export default getMutation;
import React, { FunctionComponent } from 'react';
import Styled from '@emotion/styled/macro';
import { Spinner } from 'react-bootstrap';
interface ListEmptyStateProps {
	text?: string;
	loader?: boolean;
}
export const ListEmptyState: FunctionComponent<ListEmptyStateProps> = ({ text, loader }) => {
	return (
		<Container>
			{loader && <Spinner animation="border" variant="primary" />}
			{text && <Text>{text}</Text>}
		</Container>
	);
};

export const Container = Styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.4);
	border: 1px solid #d6d6d6;
	height: 75px;
	width: 100%;
	border-radius: 4px;
`;

export const Text = Styled.p`
	margin: 0;
	padding: 0;
	color: rgba(0,0,0, 0.4);
	font-size: 28px;
	font-family: 'roboto';
	letter-spacing: 1px;
	font-weight: 500;
`;

import { gql } from "apollo-boost";

export const GET_PALLET_LISTS = gql`
{
    palletLists {
        id
        createdAt
        itemCount
        description
        shipmentCount
        lastUpdatedAt
        emailDeliveryCount
        createdBy {
            name
            id
        }
    }
}
`;
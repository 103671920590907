import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
}
const mutation = gql`
	mutation createPalletListItem($input: CreatePalletListItemInput!) {
		createPalletListItem(input: $input) {
			palletListItems {
				id
				createdAt
				poNumber
				product {
					id
					description
					eanCode
				}
			}
		}
	}
`;

const GET_PALLET_LIST = gql`
	query palletList($id: String!) {
		palletList(id: $id) {
			id
			createdAt
			itemCount
			shipmentCount
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
			items {
				id
				createdAt
				poNumber
				product {
					id
					description
					eanCode
				}
			}
		}
	}
`;

function getUpdateFunction(palletListId: string): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				createPalletListItem: { palletListItems },
			},
		}
	) => {
		const { palletList }: any = cache.readQuery({ query: GET_PALLET_LIST, variables: { id: palletListId } });
		palletList.items = palletListItems.concat([...palletList.items]);
		palletList.itemCount = palletList.items.length;
		cache.writeQuery({
			query: GET_PALLET_LIST,
			data: {
				palletList: {
					...palletList,
				},
			},
		});
	};

	return updater;
}

function getMutation(variables: { palletListId: string }, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(variables.palletListId),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

import React, {FunctionComponent} from 'react';
import {StatusIcon} from './StatusPill.styled';

export enum STATUS {
    NEW = "NEW",
    ITEMS = "ITEMS",
    SHIPMENTS = "SHIPMENTS",
    DELIVERIES = "DELIVERIES",
}
interface StatusPillProps{
    text: string;
    status:  STATUS;
}
export const StatusPill: FunctionComponent<StatusPillProps> = ({text, status}) => {
    return <StatusIcon status={status}>{text}</StatusIcon>
}
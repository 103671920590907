import React, { FunctionComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteModalProps {
	id: string;
	text: string;
	headerText: string;
	onPositive: () => void;
	onNegative: () => void;
}

export const DeleteModal: FunctionComponent<DeleteModalProps> = ({ id, text, headerText, onPositive, onNegative }) => {
	return (
		<Modal show={true} size="sm">
			<Modal.Header>{headerText}</Modal.Header>
			<Modal.Body>
				<p>
					{text}: <b>{id}</b>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onNegative} variant="secondary">
					Cancel
				</Button>
				<Button onClick={onPositive} variant="danger">
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

import React, { Fragment } from 'react';
import Styled from '@emotion/styled';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Icon from '@mdi/react';
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';
import { IProduct } from '../../models/graphql';

interface ProductListProps {
	products: IProduct[];
	selectedProductId: string | null | undefined;
	onSelectProduct: (productId: string) => void;
}
export const ProductList: React.FunctionComponent<ProductListProps> = ({ products, onSelectProduct, selectedProductId }) => {
	const handleSelectProduct = (productId: string) => {
		onSelectProduct(productId);
	};

	return (
		<Fragment>
			<ListHeader>
				<StaticHeaderItem width={30}></StaticHeaderItem>
				<StaticHeaderItem width={120}>Material Id</StaticHeaderItem>
				<FlexHeaderItem>Description</FlexHeaderItem>
			</ListHeader>
			{products.map((item) => (
				<List key={item.id} onClick={() => handleSelectProduct(item.id)}>
					<StaticItem width={30}>
						<Icon path={selectedProductId === item.id ? mdiRadioboxMarked : mdiRadioboxBlank} size={'20px'} />
					</StaticItem>
					<StaticItem width={120}>{item.id}</StaticItem>
					<FlexItem>{item.description}</FlexItem>
				</List>
			))}
		</Fragment>
	);
};
export const Container = Styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.7rem;
`;

export const Item = Styled.div`
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;

export const StaticItem = Styled(Item)<{ align?: string; width?: number }>`
    min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
    text-align: ${({ align }) => align || 'left'};
`;

export const FlexItem = Styled(Item)`
    flex: 1;
    min-width: 30px;
`;

export const List = Styled(Container)`
    background-color: #fff;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ddd;
    border-radius: 4px;
    color: #777;
    transition: 0.25s;
    &:hover {
        box-shadow: 0 1px 2px 1px  #ddd;
    }
`;

export const ListHeader = Styled(Container)`

`;

const headerStyle = () => css`
	font-size: 11px;
	font-weight: 600;
	color: rgb(17.7%, 50.9%, 79.6%);
`;
export const FlexHeaderItem = Styled(FlexItem)`
    ${headerStyle}
`;

export const StaticHeaderItem = Styled(StaticItem)`
    ${headerStyle}
`;

export const DropdownItem = Styled(StaticItem)`
    overflow: visible;
    height: 20px;
`;

export default ProductList;

import { IMutationObject } from './../models/mutation/IMutationObject';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

const mutation = gql`
	mutation createPalletList($input: CreatePalletListInput!) {
		createPalletList(input: $input) {
			palletList {
				id
				createdAt
				itemCount
				shipmentCount
				description
				lastUpdatedAt
				emailDeliveryCount
				createdBy {
					id
					name
				}
			}
		}
	}
`;

const GET_PALLET_LISTS = gql`
	{
		palletLists {
			id
			createdAt
			itemCount
			description
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
		}
	}
`;

function getUpdateFunction(): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				createPalletList: { palletList },
			},
		}
	) => {
		const { palletLists }: any = cache.readQuery({ query: GET_PALLET_LISTS });
		cache.writeQuery({
			query: GET_PALLET_LISTS,
			data: { palletLists: [palletList, ...palletLists] },
		});
	};
	return updater;
}

function getMutation(onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

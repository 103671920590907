import { IMutationObject } from './../models/mutation/IMutationObject';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

const mutation = gql`
	mutation createShipment($input: CreateShipmentInput!) {
		createShipment(input: $input) {
			shipment {
				id
				createdAt
				lastUpdatedAt
				description
				palletListCount
				emailDeliveryCount
				createdBy {
					id
					name
				}
			}
		}
	}
`;

const GET_SHIPMENTS = gql`
	{
		shipments {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
		}
	}
`;

function getUpdateFunction(): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				createShipment: { shipment },
			},
		}
	) => {
		const { shipments }: any = cache.readQuery({ query: GET_SHIPMENTS });
		cache.writeQuery({
			query: GET_SHIPMENTS,
			data: { shipments: [shipment, ...shipments] },
		});
	};
	return updater;
}

function getMutation(onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

import React, { Fragment, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled/macro';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Icon from '@mdi/react';
import { mdiDeleteOutline, mdiDeleteOffOutline } from '@mdi/js';
import { DateDisplay } from '../shared/Date/DateDisplay.component';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import getDeleteShipmentsMutation from '../../mutations/DeleteShipmentsMutation';
import { useHistory, useLocation } from 'react-router-dom';
import { StatusPill, STATUS } from '../StatusPill/StatusPill.component';
import { ApolloError } from 'apollo-boost';
import { DeleteModal } from '../modals/DeleteModal.component';
import { ListEmptyState } from '../ListEmptyState/ListEmptyState';
import { IShipment } from '../../models/graphql';

interface GraphQLShipment {
	id: string;
	createdAt: Date;
	lastUpdatedAt: Date;
	description: string;
	palletListCount: number;
	emailDeliveryCount: number;
	createdBy: {
		id: number;
		name: string;
	};
}

interface PalletListTableProps {
	idVisible?: boolean;
	nameVisible?: boolean;
	createdAtVisible?: boolean;
	palletListCountVisible?: boolean;
	emailDeliveryCountVisible?: boolean;
	updatedAtVisible?: boolean;
	createdByVisible?: boolean;
	contextVisible?: boolean;
	shipments?: GraphQLShipment[];
	loading: boolean;
}

export const ShipmentsTable: React.FunctionComponent<PalletListTableProps> = ({ shipments, loading, ...props }) => {
	const history = useHistory();
	const location = useLocation();

	const onDeleteMutationError = (error: ApolloError) => {
		alert('error');
	};
	const [deletePalletListMutation] = useMutationObject(getDeleteShipmentsMutation(null, onDeleteMutationError));

	// Should properly be moved to container
	const handleListClick = (id: string) => {
		history.push(`/dashboard/shipment-service/shipments/${id}`, { prevLocation: location.pathname });
	};

	/*------------------------------------------------------------
        State and handlers for delete shipment
    -------------------------------------------------------------*/
	// state - null hides the modal - and modal shows as soon as a id is set
	const [deleteShipmentId, setDeleteShipmentId] = useState<string | null>(null);
	const handeClickDelete = (shipmentId: string) => {
		setDeleteShipmentId(shipmentId);
	};

	// calls the delete shipment mutation and nulls the id afterwards
	const onDeletePositive = () => {
		deletePalletListMutation({ ids: [deleteShipmentId] });
		setDeleteShipmentId(null);
	};

	// nulls the id - so that the modal hides with no action
	const onDeleteNegative = () => {
		setDeleteShipmentId(null);
	};
	/*-------- end state/handlers delete pallet list section --------*/

	/**
	 * returns true if palletLsit can be deleted
	 * @param palletList
	 */
	const isDeletable = (shipment: GraphQLShipment) => shipment.emailDeliveryCount <= 0;

	/**
	 * Returns status based on palletList counts
	 * @param itemCount number of items on the list
	 * @param shipmentCount  number of shipments the list is used on
	 * @param shipmentDeliveryCount how many deliveries sent
	 */
	const getStatus = (palletListCount: number, shipmentDeliveryCount: number) => {
		if (shipmentDeliveryCount > 0) return STATUS.DELIVERIES;
		if (palletListCount > 0) return STATUS.ITEMS;
		return STATUS.NEW;
	};
	const {
		idVisible,
		nameVisible,
		createdAtVisible,
		palletListCountVisible,
		updatedAtVisible,
		createdByVisible,
		emailDeliveryCountVisible,
		contextVisible,
	} = props;

	const getShipmentList = (shipments: IShipment[]) => {
		return shipments.map((shipment) => (
			<List key={shipment.id}>
				{idVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={90}>
						<StatusPill status={getStatus(shipment.palletListCount, shipment.emailDeliveryCount)} text={shipment.id} />
					</StaticItem>
				)}
				{nameVisible && <FlexItem onClick={() => handleListClick(shipment.id)}>{shipment.description}</FlexItem>}
				{createdAtVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={140} align="center">
						<DateDisplay dateTime={shipment.createdAt} />
					</StaticItem>
				)}
				{palletListCountVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={80} align="end">
						{shipment.palletListCount}
					</StaticItem>
				)}
				{emailDeliveryCountVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={80} align="end">
						{shipment.emailDeliveryCount}
					</StaticItem>
				)}
				{updatedAtVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={140} align="center">
						{moment(shipment.lastUpdatedAt).format('HH:MM')} &nbsp;<b>{moment(shipment.lastUpdatedAt).format('DD. MMM YY')}</b>
					</StaticItem>
				)}
				{createdByVisible && (
					<StaticItem onClick={() => handleListClick(shipment.id)} width={80} align="start">
						<Ellipsis>{shipment.createdBy.name}</Ellipsis>
					</StaticItem>
				)}
				{contextVisible && (
					<StaticItem width={40} align="center">
						{isDeletable(shipment) ? (
							<HoverColor color="#ff7675" onClick={() => handeClickDelete(shipment.id)}>
								<Icon path={mdiDeleteOutline} size={'18px'} />
							</HoverColor>
						) : (
							<Icon path={mdiDeleteOffOutline} size={'18px'} />
						)}
					</StaticItem>
				)}
			</List>
		));
	};
	const getListContent = (shipments: IShipment[], loading: boolean) => {
		if (loading) return <ListEmptyState loader />;
		if (shipments.length === 0) return <ListEmptyState text="No Shipments" />;
		return getShipmentList(shipments);
	};

	return (
		<Fragment>
			<ListHeader>
				{idVisible && <StaticHeaderItem width={90}>ID</StaticHeaderItem>}
				{nameVisible && <FlexHeaderItem>Name</FlexHeaderItem>}
				{createdAtVisible && (
					<StaticHeaderItem width={140} align="center">
						Created
					</StaticHeaderItem>
				)}
				{palletListCountVisible && (
					<StaticHeaderItem width={80} align="end">
						{' '}
						List Count
					</StaticHeaderItem>
				)}
				{emailDeliveryCountVisible && <StaticHeaderItem width={80}>Sent Count</StaticHeaderItem>}
				{updatedAtVisible && (
					<StaticHeaderItem width={140} align="center">
						Last Updated
					</StaticHeaderItem>
				)}
				{createdByVisible && (
					<StaticHeaderItem width={80} align="start">
						Created By
					</StaticHeaderItem>
				)}
				{contextVisible && <StaticHeaderItem width={40} />}
			</ListHeader>
			{getListContent(shipments || [], loading)}
			{deleteShipmentId && (
				<DeleteModal
					headerText="Delete Shipment"
					text="Are you sure you want to delete the shipment with id"
					id={deleteShipmentId}
					onNegative={onDeleteNegative}
					onPositive={onDeletePositive}
				/>
			)}
		</Fragment>
	);
};

ShipmentsTable.defaultProps = {
	idVisible: true,
	nameVisible: true,
	createdAtVisible: true,
	palletListCountVisible: true,
	emailDeliveryCountVisible: true,
	updatedAtVisible: true,
	createdByVisible: true,
	contextVisible: true,
	loading: false,
};

// const Input = styled.input<{type: string, value:string}>`
//     height: calc(100% - 4px);
//     width: 100%;
//     border: 1px solid #eee;
//     border-radius: 4px;
//     &:hover {
//         border: 1px solid #ccc;
//         background-color: #f2f2f2;
//     }
//     &:active {
//         background-color: #f7f7f7;
//     }
//     &:focus {
//         outline: 1px solid ${(props: any) => props.theme.colors.blue};
//     }
// `;
export const Ellipsis = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const HoverColor = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	&:hover {
		svg {
			width: 24px;
			height: 24px;

			path {
				fill: ${(props) => props.color};
				transition: 0.2s;
			}
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: stretch;
	align-items: center;
	width: 100%;
	height: 40px;
`;

export const Item = styled.div`
	padding: 0 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StaticItem = styled(Item)<{ align?: 'center' | 'end' | 'start'; width?: number }>`
	min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	justify-content: ${({ align }) => (align ? (align === 'center' ? 'center' : align === 'end' ? 'flex-end' : 'start') : 'flex-left')};
	height: 100%;
	display: flex;
	align-items: center;
`;

export const FlexItem = styled(Item)`
	flex: 1;
	min-width: 30px;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const List = styled(Container)`
	background-color: #fff;
	margin-bottom: 1px;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	color: #777;
	transition: 0.25s;
	cursor: pointer;
	div input {
		background-color: #fff;
		transition: 0.25s;
	}

	&:hover {
		background-color: #efefef;
		div input {
			background-color: #efefef;
		}
	}
`;

export const ListHeader = styled(Container)``;

const headerStyle = () => css`
	font-size: 11px;
	font-weight: 600;
	color: rgb(17.7%, 50.9%, 79.6%);
`;
export const FlexHeaderItem = styled(FlexItem)`
	${headerStyle}
`;

export const StaticHeaderItem = styled(StaticItem)`
	${headerStyle}
`;

export const DropdownItem = styled(StaticItem)`
	overflow: visible;
	height: 20px;
`;

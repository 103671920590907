import React, { FunctionComponent, useState, useEffect, FormEvent } from 'react';
import { Modal, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Styled from '@emotion/styled/macro';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidationUtils } from '../../util/ValidationUtils';

export enum State {
	SETUP,
	SENDING,
	DONE,
	ERROR,
}

interface DeleteModalProps {
	state: State | null;
	fromAddress: string;
	emailAddress: string;
	copyAddress: string;
	subject: string;
	message: string;
	onSend: (shipmentInfo: any) => void;
	onNegative: () => void;
}

export const SendShipmentModal: FunctionComponent<DeleteModalProps> = ({
	state,
	fromAddress: fromAddressProp,
	emailAddress: emailAddressProp,
	copyAddress: copyAddressProp,
	subject: subjectProp,
	message: messageProp,
	onSend,
	onNegative,
}) => {
	const maxCount = 240;
	const maxCountTextMessage = 2000;
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [fromAddress, setFromAddress] = useState('');
	const [fromAddressError, setFromAddressError] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [emailAddressError, setEmailAddressError] = useState(false);
	const [copyAddress, setCopyAddress] = useState('');
	const [copyAddressError, setCopyAddressError] = useState(false);
	const [description, setDescription] = useState('');
	const [prefferedUnloadingDate, setPrefferedUnloadingDate] = useState<Date | null>(new Date());

	useEffect(() => {
		setSubject(subjectProp);
		setMessage(messageProp);
		setFromAddress(fromAddressProp);
		setEmailAddress(emailAddressProp);
		setCopyAddress(copyAddressProp);
	}, [fromAddressProp, subjectProp, messageProp, emailAddressProp, copyAddressProp]);

	const hasErrors = () => {
		return fromAddressError || emailAddressError || copyAddressError;
	};

	const onSendShipment = () => {
		onSend({
			description,
			prefferedDate: prefferedUnloadingDate,
			fromAddress,
			emailAddress,
			copyAddress,
			subject,
			message,
		});
	};

	const onChangeInputField = (fieldName: string, event: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const value = event.currentTarget.value;
		const maxLength = fieldName === 'message' ? maxCountTextMessage : maxCount;
		if (event.currentTarget.value.length > maxLength) {
			return;
		}
		switch (fieldName) {
			case 'description':
				setDescription(value);
				break;
			case 'fromAddress':
				setFromAddress(value);
				if (ValidationUtils.validateEmails(value, 1)) setFromAddressError(false);
				break;
			case 'emailAddress':
				setEmailAddress(value);
				if (ValidationUtils.validateEmails(value)) setEmailAddressError(false);
				break;
			case 'copyAddress':
				setCopyAddress(value);
				if (ValidationUtils.validateEmails(value, 5, 0)) setCopyAddressError(false);
				break;
			case 'subject':
				setSubject(value);
				break;
			case 'message':
				setMessage(value);
				break;
		}
	};

	return (
		<Modal centered onHide={onNegative} show={true} size="lg">
			<Modal.Header>Send Shipment</Modal.Header>
			<Modal.Body>
				<Container fluid>
					<Row>
						<Col sm={12}>
							<InputGroup>
								<label>
									Shipment Description ({description.length}/{maxCount})
								</label>
								<Input type="text" value={description} onChange={(e) => onChangeInputField('description', e)} />
							</InputGroup>
							<InputGroup>
								<label>Preffered Unloading Date</label>
								<DatePicker
									locale="da"
									dateFormat="dd-MM-yyyy"
									selected={prefferedUnloadingDate}
									onChange={(date) => setPrefferedUnloadingDate(date)}
								/>
							</InputGroup>
							<InputGroup error={fromAddressError}>
								<label>
									Sender Email ({fromAddress.length}/{maxCount})
								</label>
								<Input
									error={fromAddressError}
									type="text"
									onBlur={() => setFromAddressError(!ValidationUtils.validateEmails(fromAddress, 1))}
									value={fromAddress}
									onChange={(e) => onChangeInputField('fromAddress', e)}
								/>
							</InputGroup>
							<InputGroup error={emailAddressError}>
								<label>
									Receiver email ({emailAddress.length}/{maxCount})
								</label>
								<Input
									error={emailAddressError}
									type="text"
									value={emailAddress}
									onBlur={() => setEmailAddressError(!ValidationUtils.validateEmails(emailAddress))}
									onChange={(e) => onChangeInputField('emailAddress', e)}
								/>
							</InputGroup>
							<InputGroup error={copyAddressError}>
								<label>
									BCC Address ({copyAddress.length}/{maxCount})
								</label>
								<Input
									error={copyAddressError}
									type="text"
									value={copyAddress}
									onBlur={() => setCopyAddressError(!ValidationUtils.validateEmails(copyAddress, 5, 0))}
									onChange={(e) => onChangeInputField('copyAddress', e)}
								/>
							</InputGroup>
							<InputGroup>
								<label>
									Email Subject ({subject.length}/{maxCount})
								</label>
								<Input type="text" value={subject} onChange={(e) => onChangeInputField('subject', e)} />
							</InputGroup>
							<InputGroup>
								<label>
									Email Message ({message.length}/{maxCountTextMessage})
								</label>
								<TextArea rows={5} value={message} onChange={(e) => onChangeInputField('message', e)} />
							</InputGroup>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onNegative} variant="secondary">
					Cancel
				</Button>
				<Button disabled={hasErrors()} onClick={onSendShipment} variant="danger">
					Send!
				</Button>
			</Modal.Footer>
			{state === State.SENDING && (
				<LoadingContainer>
					<h3>Sending Shipment</h3>
					<Spinner animation="border" variant="primary" />
				</LoadingContainer>
			)}
		</Modal>
	);
};

const LoadingContainer = Styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const InputGroup = Styled.div<{ width?: number; first?: boolean; last?: boolean; error?: boolean }>`
    width: ${(props) => props.width + 'px' || 'auto'};
    flex-grow: ${(props) => !props.width && '1'};
    padding-left: ${(props) => (props.first ? '0' : '10px')};
    padding-right: ${(props) => (props.last ? '0' : '10px')};
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    label {
        font-size: 13px;
        margin-bottom: 4px;
        color: ${(props) => (props.error ? 'red' : '#777')};
    }
`;
const Input = Styled.input<any>`
    padding: 0 10px;
    border-radius: 4px;
    height: 30px;
	border: ${(props) => (props.error ? '1px solid red' : '1px solid #d6d6d6')};
    color: #777;
    font-size: 14px;
    font-weight: 600;
`;

const TextArea = Styled.textarea`
  padding: 0 10px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    color: #777;
    font-size: 14px;
    font-weight: 600;
`;

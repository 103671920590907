import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
	optimisticResponse?: any;
}

const mutation = gql`
	mutation updatePalletList($input: UpdatePalletListInput!) {
		updatePalletList(input: $input) {
			id
			description
			palletType
			updatedAt
		}
	}
`;

const GET_PALLET_LIST = gql`
	query palletList($id: String!) {
		palletList(id: $id) {
			id
			description
			palletType
			lastUpdatedAt
		}
	}
`;

function getUpdateFunction(): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				updatePalletList: { id, description, updatedAt, palletType },
			},
		}
	) => {
		const { palletList }: any = cache.readQuery({ query: GET_PALLET_LIST, variables: { id } });
		palletList.description = description;
		palletList.palletType = palletType;
		palletList.lastUpdatedAt = updatedAt;
		cache.writeQuery({
			query: GET_PALLET_LIST,
			data: { palletList },
		});
	};
	return updater;
}

function getMutation(onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

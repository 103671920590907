import React from 'react';
import Styled from '@emotion/styled/macro';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export const TextLogo = () => (
	<div>
		<H1>
			Shipment<b>Service</b>
		</H1>
	</div>
);

const H1 = Styled.h1`   
    font-family: 'roboto';
    font-size: 24px;
    font-weight: 600;
    b {
        font-family: 'open sans';
        font-weight: 100;
    }
`;

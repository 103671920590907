import React, { Fragment } from 'react';
import styled from '@emotion/styled';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Icon from '@mdi/react';
import { mdiMinusBoxOutline, mdiMinusBoxMultipleOutline } from '@mdi/js';

interface GraphQLPalletListItem {
	id: number;
	createdAt: Date;
	product: {
		id: string;
		description: string;
	};
}
interface PalletListGroupedItemTableProps {
	palletListItems: GraphQLPalletListItem[];
	isDeletable: boolean;
	onDeleteOne?: ((id: number) => void) | null | undefined;
	onDeleteMany?: ((ids: number[]) => void) | null | undefined;
	showDelete?: boolean;
}
export const PalletListsGroupedItemTable: React.FunctionComponent<PalletListGroupedItemTableProps> = ({
	palletListItems,
	isDeletable,
	onDeleteMany,
	onDeleteOne,
	showDelete,
}) => {
	const handleDeleteOneClick = (item: any) => {
		const toDeleteId = item.listItems[item.listItems.length - 1].id;
		onDeleteOne && onDeleteOne(toDeleteId);
	};

	const handleDeleteManyClick = (item: any) => {
		const toDeleteIds = item.listItems.map((item: any) => item.id);
		onDeleteMany && onDeleteMany(toDeleteIds);
	};

	const groupItemsById = (palletListItems: any[]): any[] => {
		const itemGrouping = palletListItems.reduce((grouped, item) => {
			const existingGroup = grouped[item.product.id + item.poNumber];

			if (!existingGroup) {
				grouped[item.product.id + item.poNumber] = {
					id: item.product.id + item.poNumber,
					poNumber: item.poNumber,
					product: item.product,
					listItems: [item],
				};
			} else {
				grouped[item.product.id + item.poNumber].listItems.push(item);
			}

			return grouped;
		}, {});

		return Object.values(itemGrouping);
	};

	const groupedItems = groupItemsById(palletListItems).sort((a, b) => a.id.localeCompare(b.id));
	return (
		<Fragment>
			<ListHeader>
				<StaticHeaderItem width={90}>ID</StaticHeaderItem>
				<StaticHeaderItem width={90}>PO Number</StaticHeaderItem>
				<StaticHeaderItem width={110}>EAN UPC Code</StaticHeaderItem>
				<FlexHeaderItem>Description</FlexHeaderItem>
				<StaticHeaderItem width={90}>No. of items</StaticHeaderItem>
				{showDelete && <StaticHeaderItem width={40} />}
				{showDelete && <StaticHeaderItem width={40} />}
			</ListHeader>
			{groupedItems.map((item: any, index: number) => (
				<List key={item.id}>
					<StaticItem width={90}>{item.product.id}</StaticItem>
					<StaticItem title={item.poNumber} width={90}>
						{item.poNumber}
					</StaticItem>
					<StaticItem width={110}>{item.product.eanCode}</StaticItem>
					<FlexItem>{item.product.description}</FlexItem>
					<StaticItem width={90} align="end">
						{item.listItems.length}
					</StaticItem>
					{showDelete && (
						<StaticItem width={40} align="center">
							{isDeletable && (
								<HoverColor color="#ff7675" onClick={() => handleDeleteOneClick(item)}>
									<Icon path={mdiMinusBoxOutline} size={'20px'} />
								</HoverColor>
							)}
						</StaticItem>
					)}
					{showDelete && (
						<StaticItem width={40} align="center">
							{isDeletable && (
								<HoverColor color="#ff7675" onClick={() => handleDeleteManyClick(item)}>
									<Icon path={mdiMinusBoxMultipleOutline} size={'20px'} />
								</HoverColor>
							)}
						</StaticItem>
					)}
				</List>
			))}
		</Fragment>
	);
};

PalletListsGroupedItemTable.defaultProps = {
	showDelete: true,
};

export const HoverColor = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	&:hover {
		svg {
			width: 24px;
			height: 24px;

			path {
				fill: ${(props) => props.color};
				transition: 0.2s;
			}
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: stretch;
	align-items: center;
	width: 100%;
	height: 40px;
`;

export const Item = styled.div`
	padding: 0 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StaticItem = styled(Item)<{
	align?: 'center' | 'end' | 'start';
	width?: number;
}>`
	min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	justify-content: ${({ align }) => (align ? (align === 'center' ? 'center' : align === 'end' ? 'flex-end' : 'start') : 'flex-left')};
	height: 100%;
	display: flex;
	align-items: center;
`;

export const FlexItem = styled(Item)`
	flex: 1;
	min-width: 30px;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const List = styled(Container)`
	background-color: #fff;
	margin-bottom: 1px;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	color: #777;
	transition: 0.25s;
	cursor: pointer;
	div input {
		background-color: #fff;
		transition: 0.25s;
	}

	&:hover {
		background-color: #efefef;
		div input {
			background-color: #efefef;
		}
	}
`;

export const ListHeader = styled(Container)``;

const headerStyle = () => css`
	font-size: 11px;
	font-weight: 600;
	color: rgb(17.7%, 50.9%, 79.6%);
`;
export const FlexHeaderItem = styled(FlexItem)`
	${headerStyle}
`;

export const StaticHeaderItem = styled(StaticItem)`
	${headerStyle}
`;

export const DropdownItem = styled(StaticItem)`
	overflow: visible;
	height: 20px;
`;

export default PalletListsGroupedItemTable;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SplitButton, Dropdown } from 'react-bootstrap';
import { LOCAL_STORAGE_KEYS, ListType } from '../../util/GlobalConstants';

interface NewPalletListButtonProps {
	addPalletList: (type: ListType) => void;
}

export const NewPalletListButton = ({ addPalletList }: NewPalletListButtonProps) => {
	const loadNewPalletListCreationType = (): ListType => {
		const lsValue = localStorage.getItem(LOCAL_STORAGE_KEYS.PALLET_LIST_CREATION_TYPE);
		console.log(lsValue);
		if (lsValue) {
			return lsValue as ListType;
		} else {
			return ListType.OTHER;
		}
	};
	const [newPalletListState, setNewPalletListState] = useState(loadNewPalletListCreationType());

	const updateNewPalletListCreationType = (type: ListType) => {
		console.log(type);
		localStorage.setItem(LOCAL_STORAGE_KEYS.PALLET_LIST_CREATION_TYPE, type);
		setNewPalletListState(type);
	};

	return (
		<SplitButton
			size="sm"
			onClick={() => addPalletList(newPalletListState)}
			id={`dropdown-split-variants-primary`}
			variant={'primary'}
			title={`New PalletList (${newPalletListState})`}
		>
			<Dropdown.Item
				onClick={() => {
					updateNewPalletListCreationType(ListType.MEDIA_MARKT);
					addPalletList(ListType.MEDIA_MARKT);
				}}
				eventKey="1"
			>
				MediaMarkt
			</Dropdown.Item>
			<Dropdown.Item
				onClick={() => {
					updateNewPalletListCreationType(ListType.NET_ON_NET);
					addPalletList(ListType.NET_ON_NET);
				}}
				eventKey="2"
			>
				Net on Net
			</Dropdown.Item>
			<Dropdown.Item
				onClick={() => {
					updateNewPalletListCreationType(ListType.CLAS);
					addPalletList(ListType.CLAS);
				}}
				eventKey="2"
			>
				Clas
			</Dropdown.Item>
			<Dropdown.Item
				onClick={() => {
					updateNewPalletListCreationType(ListType.OTHER);
					addPalletList(ListType.OTHER);
				}}
				eventKey="2"
			>
				Other
			</Dropdown.Item>
		</SplitButton>
	);
};

NewPalletListButton.propTypes = {
	addPalletList: PropTypes.func.isRequired,
};

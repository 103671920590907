import React from 'react';
import Styled from '@emotion/styled/macro';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { TextLogo } from './text-logo.component';
import { TopNavigation } from './top-navigation.component';
import { useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '../../auth';

export const TopMenu = () => {
	let { path, url } = useRouteMatch();
	let { logout } = useAuth0();
	return (
		<Header>
			<TextLogo />
			<div
				css={css`
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-grow: 1;
					height: 100%;
				`}
			>
				{/* menu and profile here */}
				<TopNavigation>
					<TopNavigation.NavItem exact route={`${path}`}>
						Home
					</TopNavigation.NavItem>
					<TopNavigation.NavItem route={`${path}/pallet-lists`}>Pallet Lists</TopNavigation.NavItem>
					<TopNavigation.NavItem route={`${path}/shipments`}>Shipments</TopNavigation.NavItem>
					<TopNavigation.NavItem route={`${path}/settings/delivery-settings`}>Settings</TopNavigation.NavItem>
				</TopNavigation>
				<div onClick={() => logout({ returnTo: 'https://also.iconit.dk/logout' })} style={{ cursor: 'pointer' }}>
					Log out
				</div>
			</div>
		</Header>
	);
};

const Header = Styled.header`
	padding: 0 2rem;
	display: flex;
	align-items: center;
	text-align: left;
	height: 40px;
	width: 100%;
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	z-index: 10000;
	position: fixed;
	top: 0;
`;

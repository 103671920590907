import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './auth';
import config from './config/auth-config.json';
import history from './utils/history';
import { Auth0ApolloProvider } from './components/auth0-apollo-provider/auth0-apollo-provider.component';
import { ErrorBoundary } from './components/ErrorBoundary';
import * as Sentry from '@sentry/browser';

console.log();
const onRedirectCallback = (appState: any) => {
	history.push(appState && appState.targetUrl ? appState.targetUrl : '/dashboard/shipment-service');
};
if (process.env.NODE_ENV !== 'development') {
	Sentry.init({ dsn: 'https://8ed15ecdb3b54f3bb8d1b909653fd74b@o361926.ingest.sentry.io/4071244' });
}

ReactDOM.render(
	<Auth0Provider
		domain={config.domain}
		client_id={config.clientId}
		redirect_uri={window.location.origin}
		audience={config.audience}
		onRedirectCallback={onRedirectCallback}
		scope={config.scope}
	>
		<ErrorBoundary>
			<Auth0ApolloProvider>
				<App />
			</Auth0ApolloProvider>
		</ErrorBoundary>
	</Auth0Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

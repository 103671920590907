import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
}

const mutation = gql`
	mutation deletePalletLists($input: DeletePalletListsInput!) {
		deletePalletLists(input: $input) {
			deletedIds
		}
	}
`;

const GET_PALLET_LISTS = gql`
	{
		palletLists {
			id
			createdAt
			itemCount
			description
			emailDeliveryCount
			shipmentCount
			lastUpdatedAt
			createdBy {
				name
				id
			}
		}
	}
`;

function getUpdateFunction(): MutationUpdaterFn<any> {
	const updater: MutationUpdaterFn<any> = (
		cache: any,
		{
			data: {
				deletePalletLists: { deletedIds },
			},
		}
	) => {
		const { palletLists }: any = cache.readQuery({ query: GET_PALLET_LISTS });
		cache.writeQuery({
			query: GET_PALLET_LISTS,
			data: { palletLists: palletLists.filter((list: any) => !deletedIds.includes(list.id)) },
		});
	};
	return updater;
}

function getMutation(onCompleted: ((data: any) => void) | null, onError: ((error: ApolloError) => void) | null) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(),
			onCompleted: onCompleted || undefined,
			onError: onError || undefined,
		},
	};
	return mutationObject;
}

export default getMutation;

import React, { FunctionComponent, useState, useReducer } from 'react';
import Styled from '@emotion/styled';
import { Container, Row, Col, Button, Form, SplitButton, Dropdown } from 'react-bootstrap';
import { SectionTitle } from '../../components/shared/SectionTitle/SectionTitle.component';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { PalletItemAdd } from '../../components/PalletListItemAdd/PalletListItemAdd.component';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import PalletListsGroupedItemTable from '../../components/PalletListGroupedItemTable/PalletListGroupedItemTable.component';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import { IProduct } from '../../models/graphql';
import { ChooseFromProductsModal } from '../../components/ChooseFromProductsModal/ChooseFromProductsModal.component';
import { EnterProductModal } from '../../components/EnterProductModal/EnterProductModal.component';
import { useToasts } from 'react-toast-notifications';
import { DetailsHeader, STATUS } from '../../components/DetailsHeader/DetailsHeader.component';
import { ShipmentsTable } from '../../components/ShipmentsTable/ShipmentsTable.component';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { PDFPrinter } from './palletListPdf';
import { Card as CustomCard } from '../../components/Card/Card.component';
import createPalletListItemMutation from '../../mutations/CreatePalletListItemMutation';
import deletePalletListItemsMutation from '../../mutations/DeletePalletListItemsMutation';
import createDeliveryNumberMutation from '../../mutations/CreateDeliveryNumberMutation';
import deleteDeliveryNumberMutation from '../../mutations/DeleteDeliveryNumberMutation';
import createProductMutation from '../../mutations/CreateProductMutation';
import updateProductMutation from '../../mutations/UpdateProductMutation';
import updatePalletList from '../../mutations/UpdatePalletListMutation';
import { ListType, PalletTypes, PrintType, getPrintTypeByListType } from '../../util/GlobalConstants';
interface ProductsData {
	products: IProduct[];
}

interface ProductsVars {
	input: {
		eanCode?: string;
		partialId?: string;
	};
}

interface IShowModalState {
	showSelectProduct: boolean;
	showEnterProduct: boolean;
}

const initialState: IShowModalState | never = {
	showSelectProduct: false,
	showEnterProduct: false,
};

type Action = { type: 'setShowSelectModal'; state: boolean } | { type: 'setShowEnterModal'; state: boolean };
const showModalsReducer = (state: IShowModalState, action: Action): IShowModalState => {
	switch (action.type) {
		case 'setShowEnterModal':
			return { ...state, showEnterProduct: action.state };
		case 'setShowSelectModal':
			return { ...state, showSelectProduct: action.state };
		default:
			return state;
	}
};
export const PalletListContainer: FunctionComponent<any> = () => {
	const { addToast } = useToasts();

	// active pallet list id
	const { id: palletListIdParam } = useParams();
	const [palletListId] = useState<string>(palletListIdParam || '');
	const [sixHundredInput, setSixHundredInput] = useState('');
	// Load pallet list data
	const { loading, error, data } = useQuery(GET_PALLET_LIST, {
		variables: {
			id: palletListId,
		},
	});

	if (error) {
		throw error;
	}
	/*
				Data for inserting new line items

		*/
	const [poNumber, setPoNumber] = useState<string>('');
	const [eanCode, setEanCode] = useState<string>('');
	const [numberOfItems, setNumberOfItems] = useState<string>('1');
	const [savingItem, setSavingItem] = useState<boolean>(false);
	const handlePoNumberChange = (poNumber: string) => {
		setPoNumber(poNumber);
	};
	const handleAmountChange = (amount: string) => {
		setNumberOfItems(amount);
	};
	const handleEanCodeChange = (eanCode: string) => {
		setEanCode(eanCode);
	};

	// modal state
	const [showModalState, dispatchShowModal] = useReducer(showModalsReducer, initialState);

	/*------------------------------------------------------------
				handlers for add pallet list item
		-------------------------------------------------------------*/
	const onAddPalletListItemSuccess = (data: any) => {
		console.log(data);
		addToast('Item added to palletlist', {
			appearance: 'success',
			autoDismiss: true,
		});
		setNumberOfItems('1');
		setEanCode('');
		setSavingItem(false);
	};
	const onAddPalletListItemError = (error: ApolloError) => {
		addToast('error adding item to palletlist', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [addPalletListItem] = useMutationObject(
		createPalletListItemMutation({ palletListId }, onAddPalletListItemSuccess, onAddPalletListItemError)
	);

	/*------------------------------------------------------------
				handlers for delete pallet list item
		-------------------------------------------------------------*/
	const onDeletePalletListItemsSuccess = (data: any) => {
		addToast('item(s) deleted', { appearance: 'success', autoDismiss: true });
	};
	const onDeletePalletListItemError = (error: ApolloError) => {
		addToast('error deleting items', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [deletePalletListItems] = useMutationObject(
		deletePalletListItemsMutation({ palletListId }, onDeletePalletListItemsSuccess, onDeletePalletListItemError)
	);
	/*--->--->---> end handlers for delete pallet list item <---<---<---<---*/

	/*------------------------------------------------------------
				handlers for delete delivery numbert item
		-------------------------------------------------------------*/
	const onCreateDeliveryNumberSuccess = (data: any) => {
		addToast('delivery number deleted', { appearance: 'success', autoDismiss: true });
	};
	const onCreateDeliveryNumberError = (error: ApolloError) => {
		addToast('error adding deliveryNumber', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [createDeliveryNumber] = useMutationObject(
		createDeliveryNumberMutation({ palletListId }, onCreateDeliveryNumberSuccess, onCreateDeliveryNumberError)
	);

	/*------------------------------------------------------------
				handlers for create delivery numbert item
		-------------------------------------------------------------*/
	const onDeleteDeliveryNumberSuccess = (data: any) => {
		addToast('delivery number deleted', { appearance: 'success', autoDismiss: true });
	};
	const onDeleteDeliveryNumberError = (error: ApolloError) => {
		addToast('error deleting deliveryNumber', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [deleteDeliveryNumber] = useMutationObject(
		deleteDeliveryNumberMutation({ palletListId }, onDeleteDeliveryNumberSuccess, onDeleteDeliveryNumberError)
	);

	const handleOnDeleteListItems = (ids: number[]): void => {
		deletePalletListItems({ ids });
	};

	const handleOnDeleteListItem = (id: number): void => {
		handleOnDeleteListItems([id]);
	};

	const handleSearchForEANCode = (palletListId: string, PONumber: string, products: IProduct[]) => {
		if (products.length > 1) {
			dispatchShowModal({ type: 'setShowSelectModal', state: true });
		} else if (products.length < 1) {
			dispatchShowModal({ type: 'setShowEnterModal', state: true });
		} else {
			addPalletListItemToList(palletListId, PONumber, products[0].id, +(numberOfItems || 1));
		}
	};

	const addPalletListItemToList = (palletListId: string, poNumber: string, productId: string, amount: number) => {
		addPalletListItem({
			productId: productId,
			poNumber: poNumber,
			palletListId,
			amount,
		});
	};

	// LoadProducts section
	const [eanProducts, setEanProducts] = useState<any[]>([]);
	const onGetEANProductsCompleted = (data: ProductsData) => {
		setEanProducts(data.products);
		handleSearchForEANCode(palletListId, poNumber, data.products);
	};

	const [loadEANProducts, { error: loadEANProductsError }] = useLazyQuery<ProductsData, ProductsVars>(GET_PRODUCTS, {
		onCompleted: onGetEANProductsCompleted,
		fetchPolicy: 'no-cache',
	});
	if (loadEANProductsError) {
		throw loadEANProductsError;
	}
	// END -> loadProducts section

	// LoadSearchProducts section
	const [
		loadSearchProducts,
		{
			called: isSearchProductsCalled,
			loading: isSearchProductsLoading,
			data: searchProductsData,
			variables: searchProductsVariables,
			error: loadSearchProductError,
		},
	] = useLazyQuery<ProductsData, ProductsVars>(GET_PRODUCTS, {
		fetchPolicy: 'cache-and-network',
	});
	if (loadSearchProductError) {
		throw loadSearchProductError;
	}
	// END -> loadProducts section

	// Callback for AddItem component
	const handleAddItem = () => {
		setSavingItem(true);
		loadEANProducts({ variables: { input: { eanCode: eanCode } } });
	};

	const handleSearchProducts = (partialId: string) => {
		if (isSearchProductsCalled && searchProductsVariables.input.partialId === partialId && searchProductsData) {
		} else {
			if (partialId !== null && partialId !== '') {
				loadSearchProducts({ variables: { input: { partialId } } });
			}
		}
	};

	/*------------------------------------------------------------
				handlers for update list
		-------------------------------------------------------------*/
	const onUpdateListSuccess = (data: any) => {
		addToast('pallet list updated', { appearance: 'success', autoDismiss: true });
	};
	const onUpdateListerror = (data: any) => {
		addToast('palletList update errror', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [updateList] = useMutationObject(updatePalletList(onUpdateListSuccess, onUpdateListerror));
	const handleNameChange = (name: string) => {
		updateList({ description: name, id: palletListId, palletType: data.palletList.palletType });
	};

	const handlePalletTypeChange = (palletType: string | null) => {
		updateList({ description: data.palletList.description, id: palletListId, palletType });
	};
	/*------------ end handlers add product  ----------------*/

	/*------------------------------------------------------------
				handlers for add product
		-------------------------------------------------------------*/
	const onAddProductSuccess = (data: any) => {
		if (data.createProduct.product) {
			dispatchShowModal({ type: 'setShowEnterModal', state: false });
			addToast('Product created', { appearance: 'success', autoDismiss: true });
			addPalletListItemToList(palletListId, poNumber, data.createProduct.product.id, +(numberOfItems || 1));
		}
	};
	const onAddProductError = (data: any) => {
		dispatchShowModal({ type: 'setShowEnterModal', state: false });
		addToast('Product creation failed', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [addProductMutation] = useMutationObject(createProductMutation(null, onAddProductSuccess, onAddProductError));

	/*------------ end handlers add product  ----------------*/

	/*------------------------------------------------------------
				handlers for add product
		-------------------------------------------------------------*/
	const onUpdateProductSuccess = (data: any) => {
		if (data.createProduct.product) {
			dispatchShowModal({ type: 'setShowEnterModal', state: false });
			addToast('Product updated', { appearance: 'success', autoDismiss: true });
			addPalletListItemToList(palletListId, poNumber, data.updateProduct.product.id, +(numberOfItems || 1));
		}
	};
	const onUpdateProductError = (data: any) => {
		dispatchShowModal({ type: 'setShowEnterModal', state: false });
		addToast('Product update failed', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [updateProduct] = useMutationObject(updateProductMutation(null, onUpdateProductSuccess, onUpdateProductError));

	const handleEnterProduct = (
		productId?: string,
		product?: {
			productId: string;
			description: string;
			eanCode: string;
			shouldUpdate: boolean;
		}
	) => {
		if (productId) {
			dispatchShowModal({ type: 'setShowEnterModal', state: false });
			addPalletListItemToList(palletListId, poNumber, productId, +(numberOfItems || 1));
		} else if (product) {
			if (product.shouldUpdate) {
				updateProduct({
					id: product.productId,
					description: product.description,
					eanCode: product.eanCode,
				});
			} else {
				addProductMutation({
					id: product.productId,
					description: product.description,
					eanCode: product.eanCode,
				});
			}
		}
	};

	const handleSelectProductModalOK = (
		productId?: string,
		product?: {
			productId: string;
			description: string;
			eanCode: string;
			shouldUpdate: boolean;
		}
	) => {
		dispatchShowModal({ type: 'setShowSelectModal', state: false });
		if (productId) {
			addPalletListItemToList(palletListId, poNumber, productId, +(numberOfItems || 1));
		} else if (product) {
			if (product.shouldUpdate) {
				updateProduct({
					id: product.productId,
					description: product.description,
					eanCode: product.eanCode,
				});
			} else {
				addProductMutation({
					id: product.productId,
					description: product.description,
					eanCode: product.eanCode,
				});
			}
		}
	};

	// if data does not exist we redirect to 404
	const location = useLocation();
	if (data && !data.palletList) {
		return (
			<Redirect
				to={{
					pathname: '/404',
					state: { from: location },
				}}
			/>
		);
	}
	const getDetails = (palletList: any) => {
		return [
			{
				title: 'Created By',
				text: palletList.createdBy.name,
			},
			{
				title: 'Created At',
				text: palletList.createdAt,
			},
			{
				title: 'Number of items',
				text: palletList.items.length,
			},
		];
	};

	const handleDeliveryInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (sixHundredInput.length > 0) {
				createDeliveryNumber({ deliveryNumber: sixHundredInput, palletListId });
				setSixHundredInput('');
			}
		}
	};

	const handleRemoveDeliveryNumber = (id: string) => {
		deleteDeliveryNumber({ id });
	};

	/**
	 * Returns status based on palletList counts
	 */
	const getStatus = (palletList: any) => {
		if (palletList.emailDeliveryCount > 0) return STATUS.DELIVERIES;
		if (palletList.shipmentCount > 0) return STATUS.SHIPMENTS;
		if (palletList.items.length > 0) return STATUS.ITEMS;
		return STATUS.NEW;
	};
	// prepare data
	const enterProductProducts = (searchProductsData && searchProductsData.products) || [];

	return (
		<React.Fragment>
			<Container fluid>
				<Row>
					<Col>
						{!loading && data && (
							<SectionTitle
								showBackButton={true}
								title="Pallet List"
								subtitle={palletListId}
								name={data.palletList.description}
								onNameChange={handleNameChange}
								showName={true}
							/>
						)}
					</Col>
				</Row>

				<Row>
					<Col>
						{!loading && data && (
							<React.Fragment>
								<Row>
									<Col>
										<DetailsHeader status={getStatus(data.palletList)} details={getDetails(data.palletList)} />
									</Col>
								</Row>
								<Row>
									<Col>
										<CustomCard>
											<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<SplitButton
													disabled={data.palletList.items.length <= 0}
													size="sm"
													onClick={() =>
														PDFPrinter.printPalletList(
															data.palletList,
															getPrintTypeByListType(data.palletList.palletListType)
														)
													}
													id={`dropdown-split-variants-primary`}
													variant={'primary'}
													title={`Print List (${getPrintTypeByListType(data.palletList.palletListType)})`}
												>
													<Dropdown.Item
														onClick={() => {
															PDFPrinter.printPalletList(data.palletList, PrintType.BY_PRODUCT);
														}}
														eventKey="1"
													>
														By Product
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															PDFPrinter.printPalletList(data.palletList, PrintType.BY_PO_NUMBER);
														}}
														eventKey="2"
													>
														By PO Number
													</Dropdown.Item>
												</SplitButton>
												<div key={`inline-radio`}>
													{Object.keys(PalletTypes).map((key, i) => (
														<Form.Check
															key={i}
															checked={data.palletList.palletType === PalletTypes[key].value}
															inline
															onClick={() => handlePalletTypeChange(PalletTypes[key].value)}
															label={PalletTypes[key].name}
															type={'radio'}
															id={`inline-radio-1`}
														/>
													))}
												</div>
											</div>
										</CustomCard>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<CustomCard colored title="Shipments">
											<ShipmentsTable
												shipments={data.shipments}
												loading={loading}
												createdAtVisible={false}
												createdByVisible={false}
												palletListCountVisible={false}
												contextVisible={false}
											/>
										</CustomCard>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<CustomCard colored title="Add Products">
											<PalletItemAdd
												savingItem={savingItem}
												onChangeEanCode={handleEanCodeChange}
												onChangeAmount={handleAmountChange}
												onChangePoNumber={handlePoNumberChange}
												eanCode={eanCode}
												poNumber={poNumber}
												amount={numberOfItems}
												onAddItem={handleAddItem}
											/>
										</CustomCard>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<CustomCard colored title="Add Delivery Numbers">
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<div>
													<Input
														type="text"
														value={sixHundredInput}
														onChange={(e) => setSixHundredInput(e.target.value)}
														onKeyPress={handleDeliveryInputKeyPress}
													/>
												</div>
												<div style={{ display: 'flex', flexDirection: 'row' }}>
													{data.palletList.deliveryNumbers.map((deliveryNumber: any) => {
														return (
															<ClosableBadge onClick={() => handleRemoveDeliveryNumber(deliveryNumber.id)}>
																{deliveryNumber.deliveryNumber}
																<Icon path={mdiClose} color={'#000'} size={'14px'} />
															</ClosableBadge>
														);
													})}
												</div>
											</div>
										</CustomCard>
									</Col>
								</Row>
								<Row>
									<Col>
										<CustomCard bordered title="Products">
											<PalletListsGroupedItemTable
												palletListItems={data.palletList.items}
												isDeletable={true}
												onDeleteOne={handleOnDeleteListItem}
												onDeleteMany={handleOnDeleteListItems}
											/>
										</CustomCard>
									</Col>
								</Row>
							</React.Fragment>
						)}
					</Col>
				</Row>
			</Container>
			{showModalState.showSelectProduct && (
				<ChooseFromProductsModal
					products={eanProducts}
					searchProducts={enterProductProducts}
					EANCode={eanCode}
					show={true}
					onCloseCancel={() => {
						dispatchShowModal({ type: 'setShowSelectModal', state: false });
						setSavingItem(false);
					}}
					onProductIdChange={handleSearchProducts}
					onCloseOK={handleSelectProductModalOK}
				/>
			)}

			{showModalState.showEnterProduct && (
				<EnterProductModal
					products={enterProductProducts}
					EANCode={eanCode}
					show={true}
					onCloseCancel={() => {
						dispatchShowModal({ type: 'setShowEnterModal', state: false });
						setSavingItem(false);
					}}
					onProductIdChange={handleSearchProducts}
					onCloseOK={handleEnterProduct}
				/>
			)}
		</React.Fragment>
	);
};

const GET_PALLET_LIST = gql`
	query palletList($id: String!) {
		palletList(id: $id) {
			id
			palletListType
			palletType
			createdAt
			itemCount
			description
			shipmentCount
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			deliveryNumbers {
				id
				deliveryNumber
			}
			createdBy {
				name
				id
			}
			items {
				id
				createdAt
				poNumber
				product {
					id
					description
					eanCode
				}
			}
		}
		shipments(palletListId: $id) {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
		}
	}
`;

const GET_PRODUCTS = gql`
	query products($input: ProductsInput!) {
		products(input: $input) {
			id
			description
			eanCode
		}
	}
`;

export const Input = Styled.input`
padding: 0 10px;
border-radius: 4px;
height: 30px;
width: 200px;
border: 1px solid #d6d6d6;
color: #777;
font-size: 14px;
font-weight: 600;

`;

export const ClosableBadge = Styled.div`
	cursor: pointer;
	padding: 2px 4px;
	background-color: #ddd;
	font-weight: 600;
	border-radius: 4px;
	margin-right: 16px;
	margin-top: 12px;
	font-size: 12px;
	letter-spacing: 1px;
	&:hover {
		background-color: #aaa;
		transition: 0.15s;
	}
`;

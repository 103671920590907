import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
}

const mutation = gql`
	mutation deletePalletListItems($input: DeletePalletListItemsInput!) {
		deletePalletListItems(input: $input) {
			deletedIds
		}
	}
`;

const GET_PALLET_LIST = gql`
	query palletList($id: String!) {
		palletList(id: $id) {
			id
			createdAt
			itemCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
			items {
				id
				createdAt
				product {
					id
					description
					eanCode
				}
			}
		}
	}
`;

function getUpdateFunction(palletListId: string): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				deletePalletListItems: { deletedIds },
			},
		}
	) => {
		const { palletList }: any = cache.readQuery({ query: GET_PALLET_LIST, variables: { id: palletListId } });

		const items = palletList.items.filter((item: any) => !deletedIds.includes(item.id));
		cache.writeQuery({
			query: GET_PALLET_LIST,
			data: {
				palletList: {
					...palletList,
					items,
				},
			},
		});
	};

	return updater;
}

function getMutation(variables: { palletListId: string }, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(variables.palletListId),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

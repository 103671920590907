import React, { FunctionComponent, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { gql, ApolloError } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import PalletListsTable from '../../components/palletlist-list-table/Pallet-Lists-Table.component';
import Styled from '@emotion/styled';
import { IconButton } from '../../components/shared/IconButton/Icon-Button.component';
import { StatisticCard } from '../../components/shared/statistic-card/Statistic-Card.component';
import { mdiReload } from '@mdi/js';
import { DateDisplay } from '../../components/shared/Date/DateDisplay.component';
import createPalletListMutation from '../../mutations/CreatePalletListMutation';
import createShipmentMutation from '../../mutations/CreateShipmentMutation';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import { SectionTitle } from '../../components/shared/SectionTitle/SectionTitle.component';
import { useHistory } from 'react-router-dom';
import { ShipmentsTable } from '../../components/ShipmentsTable/ShipmentsTable.component';
import { ShipmentUtils, PalletListUtils } from '../../util';
import moment from 'moment';
import { ListType } from '../../util/GlobalConstants';
import { NewPalletListButton } from '../../components/Buttons/NewPalletListButton';
import { Form } from 'react-bootstrap';
import getDeletePalletListMutation from '../../mutations/DeletePalletListsMutation';
export const PalletListOverviewContainer: FunctionComponent = () => {
	const [shipmentTypeFilter, setShipmentTypeFilter] = useState('');
	const { loading, error, data } = useQuery(GET_PALLET_LISTS);
	const [selectedPalletLists, setSelectedPalletLists] = useState<string[]>([]);
	if (error) {
		throw error;
	}

	const history = useHistory();
	const { addToast } = useToasts();
	let palletLists = [];
	let shipments = [];
	let date: Date | null = null;

	if (!loading && !error) {
		palletLists = data.palletLists.filter(PalletListUtils.filterSent).sort(PalletListUtils.sortByStatus);
		shipments = data.shipments.filter(PalletListUtils.filterSent).sort(ShipmentUtils.sortByStatus);
		date = data.stats.lastProductUploadTime;
	}

	const onDeletePalletListsMutationError = (error: ApolloError) => {
		alert('error');
	};
	const [deletePalletListMutation] = useMutationObject(getDeletePalletListMutation(null, onDeletePalletListsMutationError));
	const handleDeleteSelectedLists = () => deletePalletListMutation({ ids: selectedPalletLists });
	/*-------- end state/handlers delete pallet list xsection --------*/

	const handleSelectPalletList = (id: string) => setSelectedPalletLists(selectedPalletLists.concat([id]));

	const handleDeselectPalletList = (id: string) => setSelectedPalletLists(selectedPalletLists.filter((selectedId) => selectedId != id));

	/*------------------------------------------------------------
        State and handlers for add PalletList
    -------------------------------------------------------------*/
	const onCreatePalletListSuccess = (data: any) => {
		history.push('shipment-service/pallet-lists/' + data.createPalletList.palletList.id);
	};

	const onCreatePalletListError = (error: ApolloError) => {
		addToast('Error creating pallet list', { appearance: 'error' });
	};
	const [createPalletList] = useMutationObject(createPalletListMutation(onCreatePalletListSuccess, onCreatePalletListError));
	const addPalletList = (listType: ListType) => {
		createPalletList({ description: listType, palletListType: listType });
	};
	/*-------- end state/handlers delete pallet list section --------*/

	/*------------------------------------------------------------
        State and handlers for add PalletList
    -------------------------------------------------------------*/
	const onCreateShipmentSuccess = (data: any) => {
		history.push('shipment-service/shipments/' + data.createShipment.shipment.id);
	};

	const onCreateShipmentError = (error: ApolloError) => {
		addToast('Error creating shipment', { appearance: 'error' });
	};
	const [createShipment] = useMutationObject(createShipmentMutation(onCreateShipmentSuccess, onCreateShipmentError));
	const addShipment = () => {
		createShipment({ palletListIds: [] });
	};
	/*-------- end state/handlers delete pallet list section --------*/
	const getUploadColor = () => {
		if (!date) return '#636e72';

		const diff = moment().diff(moment(date), 'days');
		console.log('diff', diff);
		if (diff > 4) return '#ff7675';
		if (diff > 2) return '#fabc48';
		return '#00b894';
	};

	const getShipmentFilterTypes = (): { value: string; label: string }[] => {
		const options: { value: string; label: string }[] = [];
		Object.keys(ListType).forEach((key: string) => {
			options.push({
				// @ts-ignore
				value: ListType[key],
				// @ts-ignore
				label: ListType[key],
			});
		});
		return options;
	};

	const filteredPalletLists =
		shipmentTypeFilter === '' ? palletLists : palletLists.filter((list: any) => list.palletListType === shipmentTypeFilter);
	return (
		<Container fluid>
			<Row>
				<Col>
					<SectionTitle title="Overview" />
				</Col>
			</Row>
			<Row>
				<Col>
					<StatisticCard title="Products Last Updated" color={getUploadColor()} mdiIcon={mdiReload}>
						<DateDisplay dateTime={data && data.stats.lastProductUploadTime} />
					</StatisticCard>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<TitleAndButton>
								<h3>Active Pallet List</h3>
								<div>
									{selectedPalletLists.length > 0 ? (
										<>
											<span style={{ marginRight: '8px' }}>
												<Button onClick={() => setSelectedPalletLists([])} size="sm" variant={'secondary'}>
													Deselect all
												</Button>
											</span>
											<span style={{ marginRight: '8px' }}>
												<Button onClick={handleDeleteSelectedLists} size="sm" variant={'danger'}>
													Delete Selected Lists
												</Button>
											</span>
										</>
									) : null}
									<NewPalletListButton addPalletList={addPalletList} />
								</div>
							</TitleAndButton>
						</Col>
					</Row>
					<Row>
						<Col>
							<div key={`inline-radio`}>
								<Form>
									<Form.Check
										key={'no_filter'}
										checked={'' === shipmentTypeFilter}
										inline
										onClick={() => setShipmentTypeFilter('')}
										label={'No filter'}
										type={'radio'}
										id={`inline-radio-1`}
									/>
									{getShipmentFilterTypes().map((item: { value: string; label: string }) => (
										<Form.Check
											key={item.value}
											checked={item.value === shipmentTypeFilter}
											inline
											onClick={() => setShipmentTypeFilter(item.value)}
											label={item.label}
											type={'radio'}
											id={`inline-radio-1`}
										/>
									))}
								</Form>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<PalletListsTable
								onSelectPalletList={handleSelectPalletList}
								onDeselectPalletList={handleDeselectPalletList}
								selectedPalletLists={selectedPalletLists}
								checkedVisible
								palletLists={filteredPalletLists}
								loading={loading}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<TitleAndButton>
								<h3>Active Shipments</h3>
								<div>
									<IconButton onClick={addShipment} variant="primary" size="sm">
										New Shipment
									</IconButton>
								</div>
							</TitleAndButton>
						</Col>
					</Row>
					<Row>
						<Col>
							<ShipmentsTable shipments={shipments} loading={loading} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default PalletListOverviewContainer;

const GET_PALLET_LISTS = gql`
	{
		stats {
			lastProductUploadTime
		}
		palletLists {
			id
			palletListType
			createdAt
			itemCount
			description
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
		}
		shipments {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
		}
	}
`;

export const TitleAndButton = Styled.div`
    display: flex;
    justify-content: space-between;
`;

import React, { useState, memo } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Icon from '@mdi/react';
import { mdiDeleteOutline, mdiDeleteOffOutline, mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';
import { DateDisplay } from '../shared/Date/DateDisplay.component';
import { useMutationObject } from '../../graph-ql/useMutationObject';
import getDeletePalletListMutation from '../../mutations/DeletePalletListsMutation';
import { useHistory, useLocation } from 'react-router-dom';
import { StatusPill, STATUS } from '../StatusPill/StatusPill.component';
import { ApolloError } from 'apollo-boost';
import { DeleteModal } from '../modals/DeleteModal.component';
import { IPalletList } from '../../models/graphql';
import { ListEmptyState } from '../ListEmptyState/ListEmptyState';

interface GraphQLPalletList {
	id: string;
	createdAt: Date;
	itemCount: number;
	description: string;
	shipmentCount: number;
	lastUpdatedAt: Date;
	emailDeliveryCount: number;
	palletType: string;
	createdBy: {
		id: number;
		name: string;
	};
	checked?: boolean;
}

interface PalletListTableProps {
	isShipmentView?: boolean;
	checkedVisible?: boolean;
	onCheckedClick?: (id: any) => void;
	idVisible?: boolean;
	nameVisible?: boolean;
	palletTypeVisible?: boolean;
	createdAtVisible?: boolean;
	itemCountVisible?: boolean;
	updatedAtVisible?: boolean;
	createdByVisible?: boolean;
	contextVisible?: boolean;
	palletLists?: GraphQLPalletList[];
	loading: boolean;
	ignoreDeleteable?: boolean;
	selectedPalletLists: string[];
	onSelectPalletList?: (id: string) => void;
	onDeselectPalletList?: (id: string) => void;
}

export const PalletListsTable: React.FunctionComponent<PalletListTableProps> = memo(
	({ palletLists, loading, selectedPalletLists, ...props }) => {
		// const [deleteList] = useMutationObject(deletePalletListMutation())
		const history = useHistory();
		const location = useLocation();

		const onDeleteMutationError = (error: ApolloError) => {
			alert('error');
		};
		const [deletePalletListMutation] = useMutationObject(getDeletePalletListMutation(null, onDeleteMutationError));

		// Should properly be moved to container
		const handleListClick = (id: string) => {
			history.push(`/dashboard/shipment-service/pallet-lists/${id}`, {
				prevLocation: location.pathname,
			});
		};

		/*------------------------------------------------------------
        State and handlers for delete pallet list
    -------------------------------------------------------------*/
		// state - null hides the modal - and modal shows as soon as a id is set
		const [deletePalletListId, setDeletePalletListId] = useState<string | null>(null);
		const handeClickDelete = (palletListId: string) => {
			setDeletePalletListId(palletListId);
		};

		// calls the delete palletlist mutation and nulls the id afterwards
		const onDeletePositive = () => {
			deletePalletListMutation({ ids: [deletePalletListId] });
			setDeletePalletListId(null);
		};

		// nulls the id - so that the modal hides with no action
		const onDeleteNegative = () => {
			setDeletePalletListId(null);
		};
		/*-------- end state/handlers delete pallet list section --------*/

		/**
		 * returns true if palletLsit can be deleted
		 * @param palletList
		 */
		const isDeletable = (palletList: GraphQLPalletList) => palletList.shipmentCount <= 0;

		/**
		 * Returns status based on palletList counts
		 * @param itemCount number of items on the list
		 * @param shipmentCount  number of shipments the list is used on
		 * @param shipmentDeliveryCount how many deliveries sent
		 */
		const getStatus = (itemCount: number, shipmentCount: number, shipmentDeliveryCount: number) => {
			if (shipmentDeliveryCount > 0) return STATUS.DELIVERIES;
			if (shipmentCount > 0) return STATUS.SHIPMENTS;
			if (itemCount > 0) return STATUS.ITEMS;
			return STATUS.NEW;
		};
		const {
			isShipmentView,
			idVisible,
			nameVisible,
			palletTypeVisible,
			createdAtVisible,
			itemCountVisible,
			updatedAtVisible,
			createdByVisible,
			contextVisible,
			checkedVisible,
			onSelectPalletList,
			onDeselectPalletList,
			ignoreDeleteable,
		} = props;

		const Item = ({ list, onSelect, onDeselect, isChecked }: { list: any; onSelect: any; onDeselect: any; isChecked: boolean }) => {
			console.log({ list, palletLists, checkedVisible, isChecked, isDeletable: isDeletable(list) });

			return (
				<List key={list.id}>
					{checkedVisible && (
						<StaticItem
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								e.currentTarget.blur();
								!isChecked && (isDeletable(list) || isShipmentView) && onSelect(list.id);
								isChecked && onDeselect(list.id);
							}}
							width={40}
							align="center"
						>
							{isDeletable(list) || ignoreDeleteable ? (
								!isChecked ? (
									<Icon path={mdiCheckboxBlankOutline} size={'18px'} />
								) : (
									<Icon path={mdiCheckboxMarked} size={'18px'} />
								)
							) : null}
						</StaticItem>
					)}
					{idVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} width={80}>
							<StatusPill status={getStatus(list.itemCount, list.shipmentCount, list.emailDeliveryCount)} text={list.id} />
						</StaticItem>
					)}
					{nameVisible && <FlexItem onClick={() => handleListClick(list.id)}>{list.description}</FlexItem>}
					{palletTypeVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} width={80} align="center">
							{list.palletType ? list.palletType.split('_')[0] : <span style={{ color: 'red' }}>N/A</span>}
						</StaticItem>
					)}
					{createdAtVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} width={140} align="center">
							<DateDisplay dateTime={list.createdAt} />
						</StaticItem>
					)}
					{itemCountVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} width={70} align="end">
							{list.itemCount}
						</StaticItem>
					)}
					{updatedAtVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} width={130} align="center">
							<DateDisplay dateTime={list.lastUpdatedAt} />
						</StaticItem>
					)}
					{createdByVisible && (
						<StaticItem onClick={() => handleListClick(list.id)} title={list.createdBy.name} width={170} align="start">
							<Ellipsis>{list.createdBy.name}</Ellipsis>
						</StaticItem>
					)}
					{contextVisible && (
						<StaticItem width={40} align="center">
							{isDeletable(list) ? (
								<HoverColor color="#ff7675" onClick={() => handeClickDelete(list.id)}>
									<Icon path={mdiDeleteOutline} size={'18px'} />
								</HoverColor>
							) : (
								<Icon path={mdiDeleteOffOutline} size={'18px'} />
							)}
						</StaticItem>
					)}
				</List>
			);
		};

		const getPalletLists = (palletLists: GraphQLPalletList[]) => {
			return palletLists.map((list) => (
				<Item
					key={list.id}
					onSelect={onSelectPalletList}
					onDeselect={onDeselectPalletList}
					isChecked={selectedPalletLists.some((id) => id === list.id)}
					list={list}
				/>
			));
		};

		const getListContent = (palletLists: IPalletList[], loading: boolean) => {
			if (loading) return <ListEmptyState loader />;
			if (palletLists.length === 0) return <ListEmptyState text="No pallet lists" />;
			return getPalletLists(palletLists);
		};

		if (palletLists) {
			return (
				<PalletListContainer>
					<ListHeader>
						{checkedVisible && <StaticItem width={40} align="center"></StaticItem>}
						{idVisible && <StaticHeaderItem width={80}>ID</StaticHeaderItem>}
						{nameVisible && <FlexHeaderItem>Name</FlexHeaderItem>}
						{palletTypeVisible && <StaticHeaderItem width={80}>Pallet type</StaticHeaderItem>}
						{createdAtVisible && (
							<StaticHeaderItem width={140} align="center">
								Created
							</StaticHeaderItem>
						)}
						{itemCountVisible && (
							<StaticHeaderItem width={70} align="end">
								Item Count
							</StaticHeaderItem>
						)}
						{updatedAtVisible && (
							<StaticHeaderItem width={130} align="center">
								Last Updated
							</StaticHeaderItem>
						)}
						{createdByVisible && (
							<StaticHeaderItem width={170} align="start">
								Created By
							</StaticHeaderItem>
						)}
						{contextVisible && <StaticHeaderItem width={40} />}
					</ListHeader>
					{getListContent(palletLists || [], loading)}
					{deletePalletListId && (
						<DeleteModal
							headerText="Delete Pallet List"
							text="Are you sure you want to delete the pallet list with id"
							id={deletePalletListId}
							onNegative={onDeleteNegative}
							onPositive={onDeletePositive}
						/>
					)}
				</PalletListContainer>
			);
		} else {
			return <div>loading</div>;
		}
	}
);

PalletListsTable.defaultProps = {
	checkedVisible: false,
	idVisible: true,
	nameVisible: true,
	createdAtVisible: true,
	itemCountVisible: true,
	updatedAtVisible: true,
	createdByVisible: true,
	contextVisible: true,
	onCheckedClick: (id: any) => false,
};

// const Input = styled.input<{ type: string; value: string }>`
//   height: calc(100% - 4px);
//   width: 100%;
//   border: 1px solid #eee;
//   border-radius: 4px;
//   &:hover {
//     border: 1px solid #ccc;
//     background-color: #f2f2f2;
//   }
//   &:active {
//     background-color: #f7f7f7;
//   }
//   &:focus {
//     outline: 1px solid ${(props: any) => props.theme.colors.blue};
//   }
// `;

export const PalletListContainer = styled.div`
	margin-bottom: 1rem;
`;

export const Ellipsis = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const HoverColor = styled.div`
	cursor: pointer;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	&:hover {
		svg {
			width: 24px;
			height: 24px;

			path {
				fill: ${(props) => props.color};
				transition: 0.2s;
			}
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: stretch;
	align-items: center;
	width: 100%;
	height: 40px;
`;

export const Item = styled.div`
	padding: 0 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StaticItem = styled(Item)<{
	align?: 'center' | 'end' | 'start';
	width?: number;
}>`
	min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	justify-content: ${({ align }) => (align ? (align === 'center' ? 'center' : align === 'end' ? 'flex-end' : 'start') : 'flex-left')};
	height: 100%;
	display: flex;
	align-items: center;
`;

export const FlexItem = styled(Item)`
	flex: 1;
	min-width: 30px;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const List = styled(Container)`
	background-color: #fff;
	margin-bottom: 1px;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	color: #777;
	transition: 0.25s;
	cursor: pointer;
	div input {
		background-color: #fff;
		transition: 0.25s;
	}

	&:hover {
		background-color: #efefef;
		div input {
			background-color: #efefef;
		}
	}
`;

export const ListHeader = styled(Container)``;

const headerStyle = () => css`
	font-size: 11px;
	font-weight: 600;
	color: rgb(17.7%, 50.9%, 79.6%);
`;
export const FlexHeaderItem = styled(FlexItem)`
	${headerStyle}
`;

export const StaticHeaderItem = styled(StaticItem)`
	${headerStyle}
`;

export const DropdownItem = styled(StaticItem)`
	overflow: visible;
	height: 20px;
`;

export default PalletListsTable;

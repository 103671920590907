import React from 'react';
import { Router, Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { DashboardContainer } from './components/dashboard/dashboard-container.component';
import Callback from './components/callback/callback.component';
import PrivateRoute from './components/private-route/private-route.component';
import history from './utils/history';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { NotFoundContainer } from './containers/NotFound/NotFound.container';
import { IconitThemeProvider } from './components/IconitThemeProvider';

const App: React.FC = () => {
	return (
		<IconitThemeProvider>
			<ToastProvider placement={'bottom-right'} autoDismissTimeout={3000}>
				<div className="App">
					<Router history={history}>
						<Switch>
							<PrivateRoute path="/dashboard">
								<DashboardContainer />
							</PrivateRoute>
							<Route exact path="/logout">
								<Redirect to="/" />
							</Route>
							<Route exact path="/">
								<Callback />
							</Route>
							<Route path="*">
								<NotFoundContainer />
							</Route>
						</Switch>
					</Router>
				</div>
			</ToastProvider>
		</IconitThemeProvider>
	);
};

export default App;

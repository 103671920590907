import React from 'react';
// import auth from '../../auth';
import { withRouter, useHistory } from 'react-router-dom';

export const Callback = (props: any) => {
	const history = useHistory();

	const redirectOnNoCode = () => {
		if (!window.location.search.includes('code=')) {
			history.push('/dashboard');
		}
	};

	redirectOnNoCode();
	return null;
};

export default withRouter(Callback);

import { IMutationObject } from './../models/mutation/IMutationObject';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

const mutation = gql`
	mutation sendShipment($input: SendShipmentInput!) {
		sendShipment(input: $input) {
			emailDelivery {
				id
				description
				createdAt
				createdBy {
					id
					name
				}
			}
		}
	}
`;

const GET_SHIPMENT = gql`
	query shipment($id: String!) {
		shipment(id: $id) {
			id
			emailDeliveryCount
			emailDeliveries {
				id
				description
				createdAt
				createdBy {
					id
					name
				}
			}
			lists {
				id
				list {
					id
					createdAt
					itemCount
					shipmentCount
					shipmentCount
					lastUpdatedAt
					emailDeliveryCount
				}
			}
		}
	}
`;

function getUpdateFunction(shipmentId: string): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				sendShipment: { emailDelivery },
			},
		}
	) => {
		const { shipment }: any = cache.readQuery({ query: GET_SHIPMENT, variables: { id: shipmentId } });
		shipment.emailDeliveryCount += 1;
		console.log(shipment.lists);
		shipment.lists.forEach((list: any) => {
			console.log(list);
			list.list.emailDeliveryCount += 1;
		});

		shipment.emailDeliveries.push(emailDelivery);
		cache.writeQuery({
			query: GET_SHIPMENT,
			data: {
				shipment: {
					...shipment,
				},
			},
		});
	};
	return updater;
}

function getMutation(variables: { shipmentId: string }, onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(variables.shipmentId),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

import React, { FunctionComponent, useState } from 'react';

// external
import { gql, ApolloError } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import { Row, Container, Col, Button } from 'react-bootstrap';
import { Card as CustomCard } from '../../components/Card/Card.component';
// components
import { SectionTitle } from '../../components/shared/SectionTitle/SectionTitle.component';
import { DetailsHeader, STATUS } from '../../components/DetailsHeader/DetailsHeader.component';
import { IShipment } from '../../models/graphql';
import PalletListsTable from '../../components/palletlist-list-table/Pallet-Lists-Table.component';
import { PalletListUtils } from '../../util';
import PalletListsGroupedItemTable from '../../components/PalletListGroupedItemTable/PalletListGroupedItemTable.component';
import { SendShipmentModal, State } from '../../components/modals/SendShipmentModal.component';

// mutations
import { useMutationObject } from '../../graph-ql/useMutationObject';
import deletePalletListShipmentsMutation from '../../mutations/DeletePalleListShipmentsMutation';
import createPalletListShipmentMutation from '../../mutations/CreatePalletListShipmentsMutation';
import sendShipmentMutation from '../../mutations/SendShipmentMutation';
import updateShipmentMutation from '../../mutations/UpdateShipmentMutation';
import { EmailDeliveriesTable } from '../../components/EmailDeliveriesTable/EmailDeliveriesTable.component';
import { PalletTypes } from '../../util/GlobalConstants';

export const ShipmentContainer: FunctionComponent<any> = React.memo(() => {
	const [numberOfShipments, setNumberOfShipments] = useState(10);
	const [shipmentSendState, setShipmentSendState] = useState<State | null>(null);
	const { addToast } = useToasts();

	// active shipment id
	const { id: shipmentIdParam } = useParams();
	const [shipmentId] = useState<string>(shipmentIdParam || '');
	// Load shipmentdata
	const { loading, error, data } = useQuery(GET_DATA, {
		variables: {
			id: shipmentId,
		},
		fetchPolicy: 'network-only',
	});
	console.log({ data });
	if (error) {
		throw error;
	}

	/*------------------------------------------------------------
				handlers for send shipment
		-------------------------------------------------------------*/
	const onSendShipmentSuccess = (ata: any) => {
		addToast('Shipment sent', {
			appearance: 'success',
			autoDismiss: true,
		});
		setShipmentSendState(null);
	};
	const onSendShipmentError = (error: ApolloError) => {
		addToast('Error sending shipment', {
			appearance: 'error',
			autoDismiss: true,
		});
		setShipmentSendState(State.ERROR);
	};
	const [sendShipment] = useMutationObject(sendShipmentMutation({ shipmentId }, onSendShipmentSuccess, onSendShipmentError));

	const handleSendShipment = (shipmentInfo: {
		description: string;
		prefferedDate: Date;
		fromAddress: string;
		emailAddress: string;
		copyAddress: string;
		subject: string;
		message: string;
	}) => {
		setShipmentSendState(State.SENDING);
		sendShipment({
			shipmentId,
			...shipmentInfo,
		});
	};
	/*------------------------------------------------------------
				handlers for add pallet list
		-------------------------------------------------------------*/
	const onAddPalletListShipmentSuccess = (data: any) => {
		addToast('Palletlist added to shipment', {
			appearance: 'success',
			autoDismiss: true,
		});
	};
	const onAddPalletListShipmentError = (error: ApolloError) => {
		addToast('error adding pallet list to shipment', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [addPalletListShipment] = useMutationObject(
		createPalletListShipmentMutation({ shipmentId }, onAddPalletListShipmentSuccess, onAddPalletListShipmentError)
	);

	/*------------------------------------------------------------
				handlers for delete pallet list 
		-------------------------------------------------------------*/
	const onDeletePalletListShipmentsSuccess = (data: any) => {
		addToast('palletList removed', {
			appearance: 'success',
			autoDismiss: true,
		});
	};
	const onDeletePalletListShipmentError = (error: ApolloError) => {
		addToast('error removing pallet list', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [deletePalletListShipments] = useMutationObject(
		deletePalletListShipmentsMutation({ shipmentId }, onDeletePalletListShipmentsSuccess, onDeletePalletListShipmentError)
	);
	/*--->--->---> end handlers for delete pallet list item <---<---<---<---*/

	/*------------------------------------------------------------
				handlers for update shipment
		-------------------------------------------------------------*/
	const onUpdateListSuccess = (data: any) => {
		addToast('shipment updated', { appearance: 'success', autoDismiss: true });
	};
	const onUpdateListerror = (data: any) => {
		addToast('shipment update errror', {
			appearance: 'error',
			autoDismiss: true,
		});
	};
	const [updateShipment] = useMutationObject(updateShipmentMutation(onUpdateListSuccess, onUpdateListerror));
	const handleNameChange = (name: string) => {
		updateShipment({ description: name, id: shipmentId });
	};
	const getStatus = (shipment: IShipment) => {
		if (shipment.emailDeliveryCount > 0) return STATUS.DELIVERIES;
		if (shipment.palletListCount > 0) return STATUS.ITEMS;
		return STATUS.NEW;
	};

	const getDetails = (shipment: IShipment): any[] => {
		return [
			{
				title: 'Created By',
				text: shipment.createdBy.name,
			},
			{
				title: 'Created At',
				text: shipment.createdAt,
			},
			{
				title: 'Number of pallet lists',
				text: shipment.palletListCount,
			},
			{
				title: 'Number of deliveries',
				text: shipment.emailDeliveryCount,
			},
		];
	};

	const handleShowMore = () => {
		setNumberOfShipments(numberOfShipments + 10);
	};

	const handleCollapse = () => {
		setNumberOfShipments(10);
	};
	const handleDeselectPalletList = (id: any) => {
		const list = data.shipment.lists.find((list: any) => list.list.id === id);
		if (!!list) {
			deletePalletListShipments({ ids: [list.id] }, deletePalletListShipmentsMutation({ shipmentId }).optimisticResponse([list.id]));
		} else {
			addPalletListShipment(
				{ palletListIds: [id], shipmentId: shipmentId },
				createPalletListShipmentMutation({
					shipmentId,
				}).optimisticResponse(shipmentId, [id])
			);
		}
	};

	const handleSelectPalletList = (id: any) => {
		const list = data.shipment.lists.find((list: any) => list.list.id === id);
		if (!!list) {
			deletePalletListShipments({ ids: [list.id] }, deletePalletListShipmentsMutation({ shipmentId }).optimisticResponse([list.id]));
		} else {
			addPalletListShipment(
				{ palletListIds: [id], shipmentId: shipmentId },
				createPalletListShipmentMutation({
					shipmentId,
				}).optimisticResponse(shipmentId, [id])
			);
		}
	};

	const getSortedPalletLists = () => {
		const addedPalletListIds = data.shipment.lists.map((list: any) => list.list.id);
		//data.palletLists.map((palletList: any) =>  ({...palletList, checked: data.shipment.lists.some((list: any) => list.list.id === palletList.id)})).sort(PalletListUtils.sortByStatus).slice(0, numberOfShipments)
		const lists = data.palletLists.map((list: any) => ({
			checked: addedPalletListIds.includes(list.id),
			...list,
		})); // really bad naming here -O-O-
		//const restOfList = data.palletLists.filter((list:any) => !addedIds.includes(list.id)).sort(PalletListUtils.sortByStatus);
		const sortedLists: any[] = lists
			.sort((a: any, b: any) => {
				let aSplit = a.id.split('-');
				let bSplit = b.id.split('-');
				const aNumbers = +(aSplit[1] + aSplit[2]);
				const bNumbers = +(bSplit[1] + bSplit[2]);
				if (aNumbers < bNumbers) return -1;
				return 1;
			})
			.sort(PalletListUtils.sortByStatus)
			.sort((a: any, b: any) => {
				if (a.checked && b.checked) return 0;
				if (a.checked) return -1;
				else return 1;
			});
		return sortedLists;
	};

	const getItemsPyPoNumber = () => {
		const palletListItems: any[] = [];
		data.shipment.lists.forEach((list: any) =>
			list.list.items.forEach((item: any) => {
				palletListItems.push(item);
			})
		);
		return palletListItems;
	};

	const getPalletTypeCount = () => {
		console.log('getPalletTypeCount');
		const result: any[] = [];
		Object.keys(PalletTypes).forEach((key: string) => {
			const type = PalletTypes[key].value;
			let count = 0;
			data.shipment.lists.forEach((shipmentList: any) => {
				console.log(shipmentList.list);
				if (shipmentList.list.palletType === type) {
					count += 1;
				}
			});

			if (count > 0) {
				result.push({ name: PalletTypes[key].name, count });
			}
		});
		return result;
	};

	return (
		<React.Fragment>
			<Container fluid>
				<Row>
					{!loading && data && (
						<Col>
							<SectionTitle
								showBackButton={true}
								title="Shipment"
								subtitle={shipmentId}
								name={data.shipment.description}
								onNameChange={handleNameChange}
								showName={true}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						{!loading && data && (
							<DetailsHeader
								details={data ? getDetails(data.shipment) : []}
								status={data ? getStatus(data.shipment) : STATUS.SHIPMENTS}
							></DetailsHeader>
						)}
					</Col>
				</Row>
				{!loading && data && (
					<Row>
						<Col>
							<CustomCard>
								<Button onClick={() => setShipmentSendState(State.SETUP)} size="sm">
									Send shipment
								</Button>
							</CustomCard>
						</Col>
					</Row>
				)}
				{!loading && data && (
					<React.Fragment>
						<Row>
							<Col>
								<CustomCard colored title={'Deliveries'}>
									<EmailDeliveriesTable emailDeliveries={data.shipment.emailDeliveries} loading={loading} />
								</CustomCard>
							</Col>
						</Row>

						<Row>
							<Col>
								<CustomCard colored title={'Add PalletLists'}>
									<PalletListsTable
										isShipmentView
										ignoreDeleteable
										palletTypeVisible
										selectedPalletLists={data.shipment.lists.map((list: any) => list.list.id)}
										palletLists={getSortedPalletLists().slice(0, numberOfShipments)}
										loading={loading}
										checkedVisible
										contextVisible={false}
										onSelectPalletList={handleSelectPalletList}
										onDeselectPalletList={handleDeselectPalletList}
									/>
									<Row>
										<Col>
											{numberOfShipments <= data.palletLists.length && (
												<Button size={'sm'} onClick={handleShowMore} variant="primary">
													Show More...
												</Button>
											)}
											{numberOfShipments > 10 && (
												<Button size={'sm'} onClick={handleCollapse} variant="secondary">
													Minimize List
												</Button>
											)}
										</Col>
									</Row>
								</CustomCard>
							</Col>
						</Row>
						<Row>
							<Col>
								<CustomCard bordered title="Pallet Count">
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										{getPalletTypeCount().map((type, i) => {
											return (
												<div key={i} style={{ marginRight: '16px', textAlign: 'right' }}>
													<div>
														<b>{type.name}</b>
													</div>
													<div>{type.count}</div>
												</div>
											);
										})}
									</div>
								</CustomCard>
								<CustomCard bordered title="Delivery Content">
									<PalletListsGroupedItemTable
										palletListItems={getItemsPyPoNumber()}
										isDeletable={false}
										showDelete={false}
									/>
								</CustomCard>
							</Col>
						</Row>
					</React.Fragment>
				)}
			</Container>
			{shipmentSendState !== null && (
				<SendShipmentModal
					state={shipmentSendState}
					fromAddress={data.deliverySettings[0].fromAddress}
					emailAddress={data.deliverySettings[0].emailAddress}
					copyAddress={data.deliverySettings[0].copyAddress}
					subject={data.deliverySettings[0].subject}
					message={data.deliverySettings[0].message}
					onNegative={() => setShipmentSendState(null)}
					onSend={handleSendShipment}
				/>
			)}
		</React.Fragment>
	);
});

const GET_DATA = gql`
	query shipment($id: String!) {
		shipment(id: $id) {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			emailDeliveries {
				id
				description
				createdAt
				createdBy {
					id
					name
				}
			}
			createdBy {
				id
				name
			}
			lists {
				id
				list {
					id
					createdAt
					palletType
					itemCount
					shipmentCount
					shipmentCount
					lastUpdatedAt
					emailDeliveryCount
					createdBy {
						name
						id
					}
					items {
						id
						createdAt
						poNumber
						product {
							id
							description
							eanCode
						}
					}
				}
			}
		}
		palletLists {
			id
			createdAt
			itemCount
			palletType
			description
			shipmentCount
			lastUpdatedAt
			emailDeliveryCount
			createdBy {
				name
				id
			}
		}
		deliverySettings {
			id
			description
			fromAddress
			emailAddress
			copyAddress
			subject
			message
		}
	}
`;

import React, {FunctionComponent} from 'react';
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import {Button} from 'react-bootstrap';

interface IconButtonProps {
    variant?: string;
    size?: string;
    onClick?: Function;
}

export const IconButton: FunctionComponent<IconButtonProps> = (props) => {

    const buttonProps: any = {
        variant: props.variant,
    };

    if(props.size) buttonProps.size = props.size;


    return (
        <Button onClick={props.onClick} {...buttonProps}>
            <Icon 
                path={mdiPlus}
                size={0.6}
                color="white"
            />
            {props.children}
        </Button>
    )
}

IconButton.defaultProps = {
    variant: "primary",
    onClick: () => false,
}
import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
	optimisticResponse?: any;
}

const mutation = gql`
	mutation updateDeliverySetting($input: UpdateDeliverySettingInput!) {
		updateDeliverySetting(input: $input) {
			deliverySetting {
				id
				description
				fromAddress
				emailAddress
				copyAddress
				subject
				message
			}
		}
	}
`;

const getOptimisticResponse = (input: any) => {
	const response: any = {
		updateDeliverySetting: {
			__typename: 'Mutation',
			deliverySetting: {
				__typename: 'DeliverySetting',
				...input,
			},
		},
	};

	return response;
};

function getMutation(onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			onCompleted,
			onError,
		},
		optimisticResponse: getOptimisticResponse,
	};
	return mutationObject;
}

export default getMutation;

import Styled from '@emotion/styled/macro';
import { STATUS } from './StatusPill.component';

export const StatusIcon = Styled.div<{status: STATUS}>`
    background-color: ${(props: any) => {
        switch(props.status) {
            case STATUS.NEW:
                return props.theme.colors.new;
            case STATUS.ITEMS:
                return props.theme.colors.items;
            case STATUS.SHIPMENTS:
                return props.theme.colors.shipments;
            case STATUS.DELIVERIES:
                return props.theme.colors.deliveries;
            default: return "#fff";
        }
    }};
    padding: 2px 2px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display:inline-block;
    color: white;
    font-weight: 600;
    font-size: 11px;
    min-width: 100%;
    text-align:center;
`;

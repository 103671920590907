import { IShipment } from "../models/graphql";

export class ShipmentUtils {
	static sortByLastUpdated(a: IShipment, b: IShipment, direction: 1 | -1 = 1): number {
		if(a.lastUpdatedAt > b.lastUpdatedAt) return -1 * direction;
		if(a.lastUpdatedAt < b.lastUpdatedAt) return 1 * direction;
		return 0;
	}


	private static getStatus(shipment: IShipment): number {
		if(shipment.emailDeliveryCount > 0) return 2;
		if(shipment.palletListCount > 0) return 1;
		return 0;
	}
	
	/**
	 * Sort status so shipments with no pallet lists is first, with pallet lsit second
	 * and with email deliveries, last
	 * @param a 
	 * @param b 
	 */
	static sortByStatus(a: IShipment, b: IShipment, direction : 1 | -1 = 1): number {
		const aStatus = ShipmentUtils.getStatus(a);
		const bStatus = ShipmentUtils.getStatus(b);
		
		if(aStatus > bStatus) return 1 * direction;
		if(aStatus < bStatus) return -1 * direction;
		return 0;
	}

	static filterSent(shipment: IShipment): boolean {
		return shipment.emailDeliveryCount === 0;
	}
}
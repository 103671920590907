import React from 'react';
import { useAuth0 } from '../../auth';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { onError } from 'apollo-link-error';
const inMemoryCache = new InMemoryCache();

export const Auth0ApolloProvider = ({ children }: { children: any }) => {
	const { isAuthenticated, getTokenSilently } = useAuth0();
	console.log('environment', process.env.NODE_ENV);
	const GRAPHQL_API_ENDPOINT =
		process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GRAPHQL_URL_DEV : process.env.REACT_APP_GRAPHQL_URL;
	const httpLink = createHttpLink({
		uri: GRAPHQL_API_ENDPOINT,
	});
	const authMiddleware = setContext(async (operation: any) => {
		const token = isAuthenticated ? await getTokenSilently() : null;
		console.log('token', token);
		return {
			// Make sure to actually set the headers here
			headers: {
				authorization: token ? `Bearer ${token}` : null,
			},
		};
	});
	const link = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
			);

		if (networkError) console.log(`[Network error]: ${networkError}`);
	});
	const client = new ApolloClient({
		link: authMiddleware.concat(link).concat(httpLink),
		cache: inMemoryCache,
	});

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

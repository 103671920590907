import { gql } from "apollo-boost";

export const GET_SHIPMENTS = gql`
    {
        shipments {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
		}
    }
`;
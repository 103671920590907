import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
    mutation: DocumentNode;
    options: MutationHookOptions;
}

const mutation = gql`
    mutation deleteShipments($input: DeleteShipmentsInput!) {
        deleteShipments(input: $input) {
            deletedIds
        }
    }
`;

const GET_SHIPMENTS = gql`
    {
        shipments {
			id
			createdAt
			lastUpdatedAt
			description
			palletListCount
			emailDeliveryCount
			createdBy {
				id
				name
			}
		}
    }
`;

function getUpdateFunction(): MutationUpdaterFn<any> {
    const updater: MutationUpdaterFn<any> = (cache: any, {data: {deleteShipments: {deletedIds}}}) => {
        const {shipments}: any = cache.readQuery({query: GET_SHIPMENTS});
        cache.writeQuery({
            query: GET_SHIPMENTS,
            data: {shipments: shipments.filter((shipment: any) => !deletedIds.includes(shipment.id))},
        })
    }
    return updater;
}

function getMutation(onCompleted: ((data: any) => void) | null, onError: ((error: ApolloError) => void) | null) {
    const mutationObject: IMutationObject = {
        mutation,
        options: {
            update: getUpdateFunction(),
            onCompleted: onCompleted || undefined,
            onError: onError || undefined,
        },
    }
    return mutationObject;
}

export default getMutation;

import React, { FunctionComponent, useState } from 'react';
import { Modal, Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import Styled from '@emotion/styled/macro';
import { IProduct } from '../../models/graphql';
import { ProductList } from '../ProductList/ProductList.component';

interface ChooseFromProductsModalProps {
	show: boolean;
	products: IProduct[];
	searchProducts: IProduct[];
	EANCode: string;
	onCloseOK: (productId?: string, product?: { productId: string; description: string; eanCode: string; shouldUpdate: boolean }) => void;
	onCloseCancel: () => void;
	onProductIdChange: (partialId: string) => void;
}
export const ChooseFromProductsModal: FunctionComponent<ChooseFromProductsModalProps> = ({
	show,
	onCloseOK,
	onCloseCancel,
	onProductIdChange,
	searchProducts,
	products,
	EANCode,
}) => {
	const [selectedSection, setSelectedSection] = useState<'newProduct' | 'list'>('list');
	const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
	const [formProductId, setFormProductId] = useState<string>('');
	const [formDescription, setFormDescription] = useState<string>('');
	const [formEANcode, setFormEANCode] = useState<string>(EANCode);

	const onValueChange = (e: any) => {
		setSelectedProductId('');

		const { name, value } = e.currentTarget;
		switch (name) {
			case 'productId':
				setFormProductId(value);
				onProductIdChange(value);
				setSelectedSection('newProduct');
				break;
			case 'description':
				setFormDescription(value);
				setSelectedSection('newProduct');
				break;
			case 'EANCode':
				setFormEANCode(value);
				setSelectedSection('newProduct');
				break;
		}
	};

	const handleSelectProductId = (id: string) => {
		setSelectedProductId(id);
		setSelectedSection('list');
	};
	const handleCancelClick = () => onCloseCancel();
	const handleOKClick = () => {
		switch (selectedSection) {
			case 'list':
				return onCloseOK(selectedProductId || '');
			case 'newProduct': {
				const shouldUpdate = searchProducts.some((product) => product.id === formProductId);
				return onCloseOK(undefined, { productId: formProductId, description: formDescription, eanCode: formEANcode, shouldUpdate });
			}
		}
	};

	const isOkButtonDisabled = (selectedProductId: string | null, selectedSection: string) => {
		switch (selectedSection) {
			case 'list':
				return selectedProductId === null || selectedProductId === '';
			case 'newProduct':
				return formProductId.length !== 7 || formDescription.length < 5;
			default: {
				console.error('selected section invalid');
				return false;
			}
		}
	};
	return (
		<Modal show={show} onHide={handleCancelClick} animation={false} size="lg">
			<Modal.Header>
				<Modal.Title>Choose Product</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<Col>
							<h3>There was several products matching the EAN Code, chosse one or create a new product</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card border={selectedSection === 'newProduct' ? 'primary' : undefined}>
								<Card.Body>
									<Form autoComplete="off">
										<Form.Row>
											<Col sm={2} onClick={() => setSelectedProductId('')}>
												<Form.Group>
													<Form.Label>Material Id</Form.Label>
													<Form.Control
														onChange={onValueChange}
														value={formProductId}
														type="text"
														name="productId"
													/>
												</Form.Group>
											</Col>
											<Col sm={6}>
												<Form.Group>
													<Form.Label>Description</Form.Label>
													<Form.Control
														onChange={onValueChange}
														value={formDescription}
														type="text"
														name="description"
													/>
												</Form.Group>
											</Col>
											<Col sm={3}>
												<Form.Group>
													<Form.Label>EAN code</Form.Label>
													<Form.Control
														readOnly
														onChange={onValueChange}
														value={formEANcode}
														type="text"
														name="EANCode"
													/>
												</Form.Group>
											</Col>
										</Form.Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col>
							<Card border={selectedSection === 'list' ? 'primary' : undefined}>
								<Card.Body>
									<ProductList
										products={formProductId.length <= 0 ? products : searchProducts}
										onSelectProduct={handleSelectProductId}
										selectedProductId={selectedProductId}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCancelClick}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleOKClick} disabled={isOkButtonDisabled(selectedProductId, selectedSection)}>
					{selectedSection === 'list' ? 'Select' : 'Create and Select'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export const FlexContainer = Styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.7rem;
`;

export const List = Styled(FlexContainer)`
    background-color: #fff;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ddd;
    border-radius: 4px;
    color: #777;
    transition: 0.25s;
    &:hover {
        box-shadow: 0 1px 2px 1px  #ddd;
    }
`;

import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { NavLink, useRouteMatch } from 'react-router-dom';

type componentProps = {
	route: string;
	children: any;
	exact?: boolean;
};

export const NavigationItem = ({ exact, route, children }: componentProps) => {
	const match = useRouteMatch();
	return (
		<li
			css={css`
				box-sizing: border-box;
				height: 100%;
				padding: 0.4rem 0.4rem;
				a {
					text-decoration: none;
				}
			`}
		>
			<NavLink
				css={css`
					box-sizing: border-box;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					text-decoration: none;
					color: initial;
					transition: 0.2s ease;
					padding: 0rem 0.4rem;
					border-radius: 0.2rem;
					&:hover {
						background-color: lightgrey;
					}
					&.active {
						background-color: lightgrey;
					}
				`}
				exact={exact}
				to={`${route}`}
			>
				{children}
			</NavLink>
		</li>
	);
};

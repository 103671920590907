import React, { useEffect } from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useAuth0 } from '../../auth';
import { useApolloClient } from '@apollo/react-hooks';
import { ShipmentServiceContainer } from '../../containers/shipment-service/Shipment-Service.container';
import MenuContentContainer from '../../containers/menu-content/MenuContent.container';
import { IMenu } from '../../types/side-menu';
import { TopMenu } from '../top-menu/top-menu.component';

const menuTree: IMenu[] = [
	{
		name: 'Hjem',
		key: 'home',
		link: '',
		subMenus: [],
	},
	{
		name: 'Pallelister',
		key: 'pallet-lists',
		link: '/pallet-lists',
		subMenus: [],
	},
	{
		name: 'Forsendelser',
		key: 'shipments',
		link: '/shipments',
		subMenus: [],
	},
	{
		name: 'Indstillinger',
		key: 'settings',
		link: '/settings/delivery-settings',
		subMenus: [],
	},
];
export const DashboardContainer = () => {
	const { user } = useAuth0();
	const client = useApolloClient();

	useEffect(() => {
		if (user) {
			client.writeData({
				data: {
					user: { name: user.nickname, id: user.sub, __typename: 'localUser' },
				},
			});
		}
	}, [user, client]);

	let { path } = useRouteMatch();
	return (
		<React.Fragment>
			{/* <TopMenu /> */}
			<div
				className="test"
				css={css`
					margin-top: 40px;
					min-height: calc(100vh - 40px);
					display: flex;
				`}
			>
				<Switch>
					<Route path={`${path}/shipment-service`}>
						<TopMenu />
						{/* <MenuContentContainer title="Test title" menuTree={menuTree}> */}
						<ShipmentServiceContainer />
						{/* </MenuContentContainer> */}
					</Route>
					<Route path="*">
						<Redirect to={`${path}/shipment-service`} />
					</Route>
				</Switch>
			</div>
		</React.Fragment>
	);
};

import React, { FunctionComponent, useState } from 'react';
import { Modal, Row, Col, Button, Form, Card, Container } from 'react-bootstrap';
import { IProduct } from '../../models/graphql';
import { ProductList } from '../ProductList/ProductList.component';

interface EnterProductModalProps {
	show: boolean;
	EANCode: string;
	products: IProduct[];
	onCloseOK: (productId?: string, product?: { productId: string; description: string; eanCode: string; shouldUpdate: boolean }) => void;
	onCloseCancel: () => void;
	onProductIdChange: (partialId: string) => void;
}
export const EnterProductModal: FunctionComponent<EnterProductModalProps> = ({
	show,
	onCloseOK,
	onCloseCancel,
	onProductIdChange,
	EANCode,
	products,
}) => {
	const [selectedSection, setSelectedSection] = useState<'product' | 'list'>('product');
	const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
	const [formProductId, setFormProductId] = useState<string>('');
	const [formDescription, setFormDescription] = useState<string>('');
	const [formEANcode, setFormEANCode] = useState<string>(EANCode);

	const onValueChange = (e: any) => {
		setSelectedProductId('');

		const { name, value } = e.currentTarget;
		switch (name) {
			case 'productId':
				setFormProductId(value);
				onProductIdChange(value);
				setSelectedSection('product');
				break;
			case 'description':
				setFormDescription(value);
				setSelectedSection('product');
				break;
			case 'EANCode':
				setFormEANCode(value);
				setSelectedSection('product');
				break;
		}
	};
	const handleCancelClick = () => onCloseCancel();

	const handleOKClick = () => {
		if (selectedProductId && selectedProductId.trim() !== '') {
			onCloseOK(selectedProductId);
		} else {
			const shouldUpdate = products.some((product) => product.id === formProductId);
			onCloseOK(undefined, { productId: formProductId, description: formDescription, eanCode: formEANcode, shouldUpdate });
		}
	};

	const handleSelectProduct = (productId: string) => {
		setSelectedProductId(productId);
		setSelectedSection('list');
	};
	return (
		<Modal show={show} size="lg" onHide={handleCancelClick}>
			<Modal.Header>
				<Modal.Title>No Product found</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<Col>
							<h3>No Product found, enter manually and/or choose from list</h3>
						</Col>
					</Row>

					<Row>
						<Col>
							<Card border={selectedSection === 'product' ? 'primary' : undefined}>
								<Card.Body>
									<Form autoComplete="off">
										<Form.Row>
											<Col sm={2} onClick={() => setSelectedProductId('')}>
												<Form.Group>
													<Form.Label>Material Id</Form.Label>
													<Form.Control
														onChange={onValueChange}
														value={formProductId}
														type="text"
														name="productId"
													/>
												</Form.Group>
											</Col>
											<Col sm={6}>
												<Form.Group>
													<Form.Label>Description</Form.Label>
													<Form.Control
														onChange={onValueChange}
														value={formDescription}
														type="text"
														name="description"
													/>
												</Form.Group>
											</Col>
											<Col sm={3}>
												<Form.Group>
													<Form.Label>EAN code</Form.Label>
													<Form.Control
														readOnly
														onChange={onValueChange}
														value={formEANcode}
														type="text"
														name="EANCode"
													/>
												</Form.Group>
											</Col>
										</Form.Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card border={selectedSection === 'list' ? 'primary' : undefined}>
								<Card.Body>
									<ProductList
										products={products}
										onSelectProduct={handleSelectProduct}
										selectedProductId={selectedProductId}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleCancelClick} variant="secondary">
					Cancel
				</Button>
				<Button onClick={handleOKClick} variant="primary">
					{selectedSection === 'list' ? 'Select' : 'Create'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export const LOCAL_STORAGE_KEYS = {
	PALLET_LIST_CREATION_TYPE: 'pallet_list_creation_type',
};

export enum ListType {
	OTHER = 'Other',
	MEDIA_MARKT = 'MediaMarkt',
	NET_ON_NET = 'NetOnNet',
	CLAS = 'Clas',
}

export enum PrintType {
	BY_PRODUCT = 'By Product',
	BY_PO_NUMBER = 'By PO Number',
	BY_PO_AND_PRODCT = 'By PO Number and Product',
}

export const PalletTypes: { [name: string]: { value: string | null; name: string } } = {
	HALF_PALLET: {
		name: '1/2 Pallet',
		value: 'half_pallet',
	},
	EURO_PALLET: {
		value: 'euro_pallet',
		name: 'EUR Pallet',
	},
	SKIBS_PALLET: {
		name: 'SKIBS Pallet',
		value: 'skibs_pallet',
	},
	TV_PALLET: {
		name: 'TV Pallet',
		value: 'tv_pallet',
	},
	NONE: {
		name: 'N/A',
		value: null,
	},
};

export const getPrintTypeByListType = (listType: ListType) => {
	switch (listType) {
		case ListType.CLAS:
			return PrintType.BY_PRODUCT;
		case ListType.MEDIA_MARKT:
			return PrintType.BY_PRODUCT;
		case ListType.NET_ON_NET:
			return PrintType.BY_PO_NUMBER;
		case ListType.OTHER:
			return PrintType.BY_PRODUCT;
	}
	// Other: PrintType.BY_PRODUCT,
	// MediaMarkt: PrintType.BY_PRODUCT,
	// NetOnNet: PrintType.BY_PO_NUMBER,
	// Clas: PrintType.BY_PRODUCT,
};

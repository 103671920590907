import React, { FunctionComponent } from 'react';
import moment from 'moment';

export interface DateComponentProps {
	dateTime: Date;
}
export const DateDisplay: FunctionComponent<DateComponentProps> = ({ dateTime }) => {
	const momentDate = moment(dateTime);
	const time = momentDate.format('HH:MM');
	const dayAndYear = momentDate.format('DD. MMM. YYYY');
	return (
		<span>
			{time} <b>{dayAndYear}</b>
		</span>
	);
};

DateDisplay.defaultProps = {};

import { DocumentNode } from 'graphql';
import { MutationHookOptions } from '@apollo/react-hooks';
import { gql, MutationUpdaterFn, ApolloError } from 'apollo-boost';

interface IMutationObject {
	mutation: DocumentNode;
	options: MutationHookOptions;
	optimisticResponse?: any;
}

const mutation = gql`
	mutation updateShipment($input: UpdateShipmentInput!) {
		updateShipment(input: $input) {
			id
			description
			updatedAt
		}
	}
`;

const GET_DATA = gql`
	query shipment($id: String!) {
		shipment(id: $id) {
			id
			lastUpdatedAt
			description
		}
	}
`;

function getUpdateFunction(): MutationUpdaterFn {
	const updater: MutationUpdaterFn<any> = (
		cache,
		{
			data: {
				updateShipment: { id, description, updatedAt },
			},
		}
	) => {
		const { shipment }: any = cache.readQuery({ query: GET_DATA, variables: { id } });
		shipment.description = description;
		shipment.lastUpdatedAt = updatedAt;
		cache.writeQuery({
			query: GET_DATA,
			data: { shipment },
		});
	};
	return updater;
}

function getMutation(onCompleted?: (data: any) => void, onError?: (error: ApolloError) => void) {
	const mutationObject: IMutationObject = {
		mutation,
		options: {
			update: getUpdateFunction(),
			onCompleted,
			onError,
		},
	};
	return mutationObject;
}

export default getMutation;

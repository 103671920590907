import React, { FunctionComponent } from 'react';

export const NotFoundContainer: FunctionComponent<any> = (props) => {
	return (
		<div>
			<h2>Whoopsie Doo</h2>
			<p>tTe page your looking for does not exist</p>
		</div>
	);
};

import React, { FunctionComponent, useEffect, useState, FormEvent, KeyboardEvent } from 'react';
import Styled from '@emotion/styled/macro';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory, useLocation } from 'react-router-dom';

export interface SectionTitleProps {
	title: string;
	subtitle: string;
	showBackButton: boolean;
	name?: string;
	onNameChange?: (name: string) => void;
	showName?: boolean;
}
export const SectionTitle: FunctionComponent<any> = ({
	title,
	subtitle,
	showBackButton = false,
	name,
	showName,
	onNameChange = (name: any) => false,
}) => {
	const history = useHistory();
	const location = useLocation();
	const handleBackClick = () => {
		if (location.state) {
			history.goBack();
		} else {
			history.push('/dashboard/shipment-service');
		}
	};

	const [listName, setListName] = useState(name);

	useEffect(() => {
		setListName(name);
	}, [name]);
	const handleNameInputChange = (event: FormEvent<HTMLInputElement>) => {
		if (event.currentTarget.value.length <= 240) {
			setListName(event.currentTarget.value);
		}
	};

	const handleNameChange = () => {
		onNameChange(listName);
	};

	const handleEnterOnNameInput = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.currentTarget.blur();
		}
	};
	return (
		<Container>
			{showBackButton && (
				<span onClick={handleBackClick}>
					<Icon className="back-icon" path={mdiArrowLeft} size={'3rem'} />
				</span>
			)}
			<div>
				<Title>{title}</Title>
				{subtitle && <Subtitle>{subtitle}</Subtitle>}
			</div>
			{showName && (
				<Input
					type="text"
					value={listName}
					placeholder="Set list name"
					onChange={handleNameInputChange}
					onBlur={handleNameChange}
					onKeyDown={handleEnterOnNameInput}
				/>
			)}
		</Container>
	);
};

const Input = Styled.input`
    font-size: 2rem;
    flex-grow: 1;
    text-overflow: ellipsis;
    background-color: transparent;
    border: none;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
        border: 1px dotted #aaa;
    }
    &:focus {
        border: 1px solid #aaa;
    }
`;

const Container = Styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: center;
    .back-icon {
        cursor: pointer;
        path {
            fill: grey;
            transition: 0.5s;
        }
    }
    .back-icon:hover {
        path {
            fill: #2b90e7;
            
        }
    }
`;

const Title = Styled.h3`
`;

const Subtitle = Styled.h4`
    color: #2b90e7;
    
`;

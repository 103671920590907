import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { DateDisplay } from '../shared/Date/DateDisplay.component';
import { ListEmptyState } from '../ListEmptyState/ListEmptyState';

interface GraphQLEmailDeliveries {
	id: string;
	createdAt: Date;
	description: string;
	createdBy: {
		id: number;
		name: string;
	};
}

interface PalletListTableProps {
	descriptionVisible?: boolean;
	createdAtVisible?: boolean;
	createdByVisible?: boolean;

	emailDeliveries?: GraphQLEmailDeliveries[];
	loading: boolean;
}

export const EmailDeliveriesTable: React.FunctionComponent<PalletListTableProps> = ({ emailDeliveries, loading, ...props }) => {
	const { descriptionVisible, createdAtVisible, createdByVisible } = props;

	const getDeliveries = (deliveries: GraphQLEmailDeliveries[]) => {
		return deliveries.map((list) => (
			<List key={list.id}>
				{descriptionVisible && <FlexItem>{list.description}</FlexItem>}
				{createdAtVisible && (
					<StaticItem width={140} align="center">
						<DateDisplay dateTime={list.createdAt} />
					</StaticItem>
				)}
				{createdByVisible && (
					<StaticItem width={180} align="start" title={list.createdBy.name}>
						<Ellipsis>{list.createdBy.name}</Ellipsis>
					</StaticItem>
				)}
			</List>
		));
	};

	const getListContent = (deliveries: GraphQLEmailDeliveries[], loading: boolean) => {
		if (loading) return <ListEmptyState loader />;
		if (deliveries.length === 0) return <ListEmptyState text="No deliveries sent yet" />;
		return getDeliveries(deliveries);
	};

	if (emailDeliveries) {
		return (
			<EmailDeliveriesContainer>
				<ListHeader>
					{descriptionVisible && <FlexHeaderItem>Name</FlexHeaderItem>}
					{createdAtVisible && (
						<StaticHeaderItem width={140} align="center">
							Created
						</StaticHeaderItem>
					)}
					{createdByVisible && (
						<StaticHeaderItem width={180} align="start">
							Created By
						</StaticHeaderItem>
					)}
				</ListHeader>
				{getListContent(emailDeliveries || [], loading)}
			</EmailDeliveriesContainer>
		);
	} else {
		return <div>loading</div>;
	}
};

EmailDeliveriesTable.defaultProps = {
	descriptionVisible: true,
	createdAtVisible: true,
	createdByVisible: true,
};

export const EmailDeliveriesContainer = styled.div`
	margin-bottom: 1rem;
`;

export const Ellipsis = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const HoverColor = styled.div`
	cursor: pointer;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	&:hover {
		svg {
			width: 24px;
			height: 24px;

			path {
				fill: ${(props) => props.color};
				transition: 0.2s;
			}
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: stretch;
	align-items: center;
	width: 100%;
	height: 40px;
`;

export const Item = styled.div`
	padding: 0 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StaticItem = styled(Item)<{
	align?: 'center' | 'end' | 'start';
	width?: number;
}>`
	min-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	max-width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
	justify-content: ${({ align }) => (align ? (align === 'center' ? 'center' : align === 'end' ? 'flex-end' : 'start') : 'flex-left')};
	height: 100%;
	display: flex;
	align-items: center;
`;

export const FlexItem = styled(Item)`
	flex: 1;
	min-width: 30px;
	height: 100%;
	display: flex;
	align-items: center;
`;

export const List = styled(Container)`
	background-color: #fff;
	margin-bottom: 1px;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	color: #777;
	transition: 0.25s;
	cursor: pointer;
	div input {
		background-color: #fff;
		transition: 0.25s;
	}

	&:hover {
		background-color: #efefef;
		div input {
			background-color: #efefef;
		}
	}
`;

export const ListHeader = styled(Container)``;

const headerStyle = () => css`
	font-size: 11px;
	font-weight: 600;
	color: rgb(17.7%, 50.9%, 79.6%);
`;
export const FlexHeaderItem = styled(FlexItem)`
	${headerStyle}
`;

export const StaticHeaderItem = styled(StaticItem)`
	${headerStyle}
`;

export const DropdownItem = styled(StaticItem)`
	overflow: visible;
	height: 20px;
`;

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ListType, PrintType } from '../../util/GlobalConstants';

export class PDFPrinter {
	public static getDataByPONumber(items: any[]): { poNumbers: string[]; data: any[][]; headers: string[][] } {
		const poNumbers = new Set<string>();
		console.log('hello', items);
		const sortedItems = items.reduce((items: any, item: any) => {
			console.log('hello', { items, item });
			const existingGroup = items[item.product.id + '-' + item.poNumber];
			poNumbers.add(item.poNumber);
			if (!existingGroup) {
				items[item.product.id + '-' + item.poNumber] = {
					id: item.product.id,
					description: item.product.description,
					count: 1,
					poNumber: item.poNumber,
				};
			} else {
				items[item.product.id + '-' + item.poNumber].count += 1;
			}
			return items;
		}, {});
		return {
			poNumbers: Array.from(poNumbers.values()),
			data: Object.keys(sortedItems).map((key) => [
				sortedItems[key].description,
				sortedItems[key].count,
				sortedItems[key].poNumber,
				sortedItems[key].id,
			]),
			headers: [['Description', 'Quantity', 'PO Number', 'Material No.']],
		};
	}

	public static getDataByProductNumber(items: any[]): { poNumbers: string[]; data: any[][]; headers: string[][] } {
		const poNumbers = new Set<string>();

		const sortedItems = items.reduce((items: any, item: any) => {
			const existingGroup = items[item.product.id];
			poNumbers.add(item.poNumber);
			if (!existingGroup) {
				items[item.product.id] = {
					id: item.product.id,
					description: item.product.description,
					count: 1,
				};
			} else {
				items[item.product.id].count += 1;
			}

			return items;
		}, {});

		return {
			poNumbers: Array.from(poNumbers.values()),
			data: Object.keys(sortedItems).map((key) => [sortedItems[key].description, sortedItems[key].count, sortedItems[key].id]),
			headers: [['Description', 'Quantity', 'Material No.']],
		};
	}

	public static printPalletList(palletList: any, printType: PrintType) {
		console.log(printType);
		const unit = 'pt';
		const size = 'A4';
		const orientation = 'portrait';

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		const title = palletList.id + ': ' + palletList.description;

		let result = null;
		switch (printType) {
			case PrintType.BY_PRODUCT: {
				result = PDFPrinter.getDataByProductNumber(palletList.items);
				break;
			}
			case PrintType.BY_PO_NUMBER:
			default: {
				result = PDFPrinter.getDataByPONumber(palletList.items);
				break;
			}
		}

		const content = {
			startY: 50,
			head: result.headers,
			body: result.data,
		};
		doc.setFontSize(24);

		doc.text(title, marginLeft, 40);
		// @ts-ignore
		doc.autoTable(content);
		let poNumbersString = '';
		let count = 0;
		result.poNumbers.forEach((poNumber) => {
			poNumbersString += (count === 0 ? '' : ', ') + poNumber;
			count++;
		});
		let deliveryNumbersString = '';
		count = 0;
		palletList.deliveryNumbers.forEach((deliveryNumber: any) => {
			deliveryNumbersString += (count === 0 ? '' : ', ') + deliveryNumber.deliveryNumber;
			count++;
		});
		// @ts-ignore
		let finalY = doc.lastAutoTable.finalY;
		doc.setFontSize(10);
		finalY = PDFPrinter.addWrappedText('PO Numbers:', 525, doc, 10, 'bold', 10, 40, finalY + 20);
		finalY = PDFPrinter.addWrappedText(poNumbersString, 525, doc, 10, 'normal', 10, 40, finalY + 15);
		finalY = PDFPrinter.addWrappedText('Delivery Numbers:', 525, doc, 10, 'bold', 10, 40, finalY + 20);
		finalY = PDFPrinter.addWrappedText(deliveryNumbersString, 525, doc, 10, 'normal', 10, 40, finalY + 15);
		const blob = doc.output('blob', { filename: 'test.pdf' });
		var fileURL = window.URL.createObjectURL(blob);
		window.open(fileURL, '_blank');
	}

	private static addWrappedText(
		text: string,
		textWidth: number,
		doc: jsPDF,
		fontSize = 10,
		fontType = 'normal',
		lineSpacing = 7,
		xPosition = 10,
		initialYPosition = 10,
		pageWrapInitialYPosition = 10
	) {
		var textLines: string[] = doc.splitTextToSize(text, textWidth); // Split the text into lines
		var pageHeight = doc.internal.pageSize.height; // Get page height, well use this for auto-paging
		doc.setFontType(fontType);
		doc.setFontSize(fontSize);

		var cursorY = initialYPosition;

		textLines.forEach((lineText) => {
			if (cursorY > pageHeight) {
				// Auto-paging
				doc.addPage();
				cursorY = pageWrapInitialYPosition;
			}
			doc.text(xPosition, cursorY, lineText);
			cursorY += lineSpacing;
		});

		return cursorY;
	}
}

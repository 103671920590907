import React, { FunctionComponent, FormEvent, useRef, KeyboardEvent } from 'react';
import Styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';
export const PalletItemAdd: FunctionComponent<any> = ({
	eanCode,
	poNumber,
	amount,
	onAddItem,
	onChangeEanCode,
	onChangeAmount,
	onChangePoNumber,
	savingItem,
}) => {
	let eanCodeInputRef = useRef<HTMLInputElement>(null);
	let createButtonRef = useRef(null);

	const validateEanCode = (eanCode: string) => {
		let valid = true;
		valid = valid && eanCode.length > 0;
		return valid;
	};

	const validatePoNumber = (poNumber: string) => {
		let valid = true;
		valid = valid && poNumber.length > 0;
		return valid;
	};

	const validateNumberOfItems = (numberOfItems: any) => {
		return +numberOfItems > 0 && +numberOfItems <= 1000;
	};
	const handleEanCodeChange = (e: FormEvent<HTMLInputElement>) => {
		onChangeEanCode(e.currentTarget.value);
	};

	const handlePONumberChange = (e: any) => {
		onChangePoNumber(e.target.value);
	};

	const handleNumberOfItemsChange = (e: FormEvent<HTMLInputElement>) => {
		onChangeAmount(e.currentTarget.value);
	};

	const createItems = () => {
		onAddItem && onAddItem();
	};

	const handleCreateButtonClick = () => {
		isInputValid && createItems();
	};

	const handleClearPONumberButtonClick = () => {
		onChangePoNumber('');
	};

	const isInputValid = () => {
		return validateEanCode(eanCode) && validatePoNumber(poNumber) && validateNumberOfItems(amount);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && isInputValid()) {
			createItems();
		}
	};

	const handleAmountFocus = (e: FormEvent<HTMLInputElement>) => {
		e.currentTarget.select();
	};

	return (
		<React.Fragment>
			<AddContainer>
				<InputGroup first={true} last={true}>
					<label>PO number</label>
					<Input type="text" value={poNumber} onChange={handlePONumberChange} />
				</InputGroup>
				<InputContainer>
					<InputGroup first={true} width={80}>
						<label>Amount</label>
						<Input onFocus={handleAmountFocus} type="number" min="1" value={amount} onChange={handleNumberOfItemsChange} />
					</InputGroup>
					<InputGroup last={true}>
						<label>EAN Code</label>
						<Input onKeyDown={handleKeyDown} ref={eanCodeInputRef} type="text" value={eanCode} onChange={handleEanCodeChange} />
					</InputGroup>
				</InputContainer>
				<InputContainer>
					<InputGroup first={true}>
						<Button disabled={savingItem || !isInputValid()} ref={createButtonRef} onClick={handleCreateButtonClick}>
							{savingItem ? 'Saving' : 'Create'}
						</Button>
					</InputGroup>
					<InputGroup last={true}>
						<Button onClick={handleClearPONumberButtonClick} variant="secondary">
							Clear PO Number
						</Button>
					</InputGroup>
				</InputContainer>
			</AddContainer>
		</React.Fragment>
	);
};

const AddContainer = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputContainer = Styled.div`
    display: flex;
    flex-direction: row;

`;

const InputGroup = Styled.div<{ width?: number; first?: boolean; last?: boolean }>`
    width: ${(props) => props.width + 'px' || 'auto'};
    flex-grow: ${(props) => !props.width && '1'};
    padding-left: ${(props) => (props.first ? '0' : '10px')};
    padding-right: ${(props) => (props.last ? '0' : '10px')};
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    label {
        font-size: 13px;
        margin-bottom: 4px;
        color: #777;
    }
`;
const Input = Styled.input`
    padding: 0 10px;
    border-radius: 4px;
    height: 30px;
    border: 1px solid #d6d6d6;
    color: #777;
    font-size: 14px;
    font-weight: 600;

`;

import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'emotion-theming';

const theme = {
	colors: {
		new: '#636e72',
		items: '#00b894',
		shipments: '#fabc48',
		deliveries: '#ff7675',
		red: '#ff7675',
		blue: '#0984e3',
	},
};

export const IconitThemeProvider: FunctionComponent = ({ children }) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

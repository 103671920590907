import React, { FunctionComponent } from 'react';
import Styled from '@emotion/styled/macro';

export enum STATUS {
	NEW = 'NEW',
	ITEMS = 'ITEMS',
	SHIPMENTS = 'SHIPMENTS',
	DELIVERIES = 'DELIVERIES',
}
interface DetailsHeaderProps {
	status: STATUS;
	details: {
		title: string;
		text: string;
	}[];
}
export const DetailsHeader: FunctionComponent<DetailsHeaderProps> = ({ status, details }) => {
	return (
		<Container status={status}>
			{details.map((detail, i) => (
				<Detail key={i}>
					<DetailTitle>{detail.title}</DetailTitle>
					<DetailText>{detail.text}</DetailText>
				</Detail>
			))}
		</Container>
	);
};

const Detail = Styled.div`
   margin: 0 1rem 0 0;
   display: flex;
   flex-direction: column;
`;

const DetailTitle = Styled.p`
    font-size: 13px;
	font-family: 'roboto';
	letter-spacing: 1px;
	text-transform: uppercase;
    font-weight: 600;
`;

const DetailText = Styled.p`
	font-size: 13px;
	font-weight: 400;
`;

const Container = Styled.div<{ status: STATUS }>`
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom: ${(props: any) => {
		switch (props.status) {
			case STATUS.NEW:
				return `6px solid ${props.theme.colors.new}`;
			case STATUS.ITEMS:
				return `6px solid ${props.theme.colors.items}`;
			case STATUS.SHIPMENTS:
				return `6px solid ${props.theme.colors.shipments}`;
			case STATUS.DELIVERIES:
				return `6px solid ${props.theme.colors.deliveries}`;
			default:
				return '6px solid #fff';
		}
	}};

    display: flex;
	color: #000;
	align-items: center;
	justify-content: space-between;

	border-radius: 4px 4px 1px 1px;
    flex-direction: row;
	padding: 0 1rem;
	height: 50px;
	width: 100%;
	margin-bottom: 1rem;
`;
